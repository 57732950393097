import React from "react"
import withStyles from "@material-ui/core/styles/withStyles"
import classnames from "classnames"

import Card from "@material-ui/core/Card"
import ScrollFrame from "ScrollFrame"

const styleSheet = theme => ({
  root: {
    width: "100%",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    position: "relative",
    minHeight: 500,
    overflow: "visible",

    [theme.breakpoints.down("xs")]: {
      paddingTop: 0,
      paddingBottom: 0,
      flex: 1,
      borderRadius: 0,
      display: "flex",
      boxShadow: "none",
      minHeight: 0,
    },
  },
})

function StaticDialog({
  children,
  classes,
  className: classNameProp,

  ...otherProps
}) {
  const className = classnames(classes.root, classNameProp)

  return (
    <Card className={className} {...otherProps}>
      <ScrollFrame>{children}</ScrollFrame>
    </Card>
  )
}

export default withStyles(styleSheet)(StaticDialog)
