import React from "react"
import PropTypes from "prop-types"

import { withStyles } from "@material-ui/core/styles"
import classnames from "classnames"

import ErrorBoundary from "ErrorBoundary"

export const styleSheet = theme => ({
  root: {
    display: "flex",
    flex: 1,
    position: "relative",
    overflow: "hidden",
  },

  shadow: {
    transition: theme.transitions.create("box-shadow", {
      duration: theme.transitions.duration.shortest,
    }),
    display: "block",
    content: '" "',
    height: theme.spacing(2),
    position: "absolute",
    left: theme.spacing(-2),
    top: theme.spacing(-2),
    right: theme.spacing(-2),
    boxShadow: theme.shadows[0],
    zIndex: theme.zIndex.appBar - 1,
  },

  container: {
    overflow: "scroll",
    "-webkit-overflow-scrolling": "touch",
    flex: 1,
    maxHeight: "100%",
    maxWidth: "100%",
  },

  scrolled: {
    "& > $shadow": {
      boxShadow: theme.shadows[4],
    },
  },

  hasChildContainer: {
    "& > $container": {
      "-webkit-overflow-scrolling": "auto",
    },
  },

  flex: {
    "& > $container": {
      display: "flex",
      flexDirection: "column",
    },
  },
})

class ScrollContainer extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    flex: PropTypes.bool,
    rootRef: PropTypes.func,
    scrolled: PropTypes.bool,

    onScroll: PropTypes.func,
  }

  static contextTypes = { scrollTouch: PropTypes.object }
  static childContextTypes = { scrollTouch: PropTypes.object }

  state = { scrolled: false, stack: 1 }

  getChildContext() {
    return {
      scrollTouch: {
        increment: this._incrementStack,
        decrement: this._decrementStack,
      },
    }
  }

  componentDidMount() {
    const { scrollTouch } = this.context
    if (scrollTouch) {
      scrollTouch.increment()
    }
  }

  componentWillUnmount() {
    const { scrollTouch } = this.context
    if (scrollTouch) {
      scrollTouch.decrement()
    }
  }

  _incrementStack = () => {
    this.setState({ stack: this.state.stack + 1 })
  }

  _decrementStack = () => {
    this.setState({ stack: this.state.stack - 1 })
  }

  _handleScroll = e => {
    if (e.target !== this._scrollContainer) {
      return
    }

    const scrolled = e.target.scrollTop > 0

    if (this.state.scrolled !== scrolled) {
      this.setState({ scrolled })
    }

    if (this.props.onScroll) {
      this.props.onScroll(e)
    }
  }

  _captureScrollContainer = c => {
    const { rootRef } = this.props

    this._scrollContainer = c

    if (rootRef) {
      rootRef(c)
    }
  }

  render() {
    const {
      classes,
      className: classNameProp,
      children,
      flex,
      rootRef,
      scrolled: scrolledProp,

      ...otherProps
    } = this.props
    const { scrolled } = this.state
    const className = classnames(
      classes.root,
      {
        [classes.scrolled]: scrolledProp === undefined ? scrolled : scrolledProp,
        [classes.flex]: flex,
      },
      classNameProp
    )

    return (
      <div {...otherProps} className={className}>
        <ErrorBoundary>
          <div
            onScroll={this._handleScroll}
            className={classes.container}
            ref={this._captureScrollContainer}
          >
            {children}
          </div>
          <div className={classes.shadow} />
        </ErrorBoundary>
      </div>
    )
  }
}

export default withStyles(styleSheet)(ScrollContainer)
