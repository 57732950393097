import React from "react"
import withStyles from "@material-ui/core/styles/withStyles"
import { fade } from "@material-ui/core/styles/colorManipulator"
import blueGrey from "@material-ui/core/colors/blueGrey"

import { Link } from "react-router-dom"

import { Caption } from "Text"
import { Container } from "Grid"

const styleSheet = theme => ({
  root: {
    backgroundColor: blueGrey[900],
    color: fade(theme.palette.getContrastText(blueGrey[900]), 0.56),
    padding: [theme.spacing(), 0],

    "& a": {
      color: "inherit",
    },
  },

  cols: {
    display: "flex",
    alignItems: "center",

    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },

  col: {
    margin: [theme.spacing(), 0],
  },

  copyright: {
    extend: "col",

    [theme.breakpoints.up("sm")]: {
      flex: 1,
    },
  },

  link: {
    color: "inherit",
    "& + &": {
      marginLeft: theme.spacing(),
    },
  },
})

function FooterLegal({ classes }) {
  return (
    <nav className={classes.root}>
      <Container wide>
        <div className={classes.cols}>
          <Caption component="div" paragraph={false} className={classes.copyright} color="inherit">
            Copyright &copy; 2017-{new Date().getFullYear()} Julie Alexander Lactation Support, LLC.
          </Caption>
          <Caption component="div" color="inherit" className={classes.col}>
            <Link to="/terms" className={classes.link}>
              Terms
            </Link>
            <Link to="/privacy" className={classes.link}>
              Privacy
            </Link>
          </Caption>
        </div>
      </Container>
    </nav>
  )
}

export default withStyles(styleSheet)(FooterLegal)
