import React from "react"
import PropTypes from "prop-types"
import withStyles from "@material-ui/core/styles/withStyles"

import { Text, Header } from "Text"

import Rating from "ui-Rating"

const styleSheet = theme => ({
  root: {},
  rating: {
    marginRight: theme.spacing(),
  },
})

function Testimony({ classes, testimony, rating, name }) {
  return (
    <div className={classes.root}>
      <Text>
        <Rating value={rating} className={classes.rating} />
        {testimony}
      </Text>
      <Header>- {name}</Header>
    </div>
  )
}

Testimony.propTypes = {
  name: PropTypes.string,
  rating: PropTypes.number,
  testimony: PropTypes.string,
}

export default withStyles(styleSheet)(Testimony)
