import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Slide from "@material-ui/core/Slide";
import { Container, Row, Col } from "Grid";
import { Text } from "Text";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";

import { Link } from "react-router-dom";

const LeaveDate = new Date(2023, 6, 16);
const ReturnDate = new Date(2023, 8, 5);

const styleSheet = theme => {
  return {
    root: {
      position: "sticky",
      bottom: theme.spacing(2),
      marginBottom: theme.spacing(4)
    },
    card: {
      background: theme.palette.primary.main,
      color: theme.palette.getContrastText(theme.palette.primary.main)
    },
    cardContent: {
      ["&:last-child"]: {
        paddingBottom: theme.spacing(2)
      }
    }
  };
};

function shortDate(date) {
  return date.toLocaleDateString("en", { month: "long", day: "numeric" });
}

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class AwayNotice extends React.Component {
  state = {
    dismissedModal: !!window.sessionStorage.getItem("dismissedAwayModal")
  };

  _handleCloseModal = () => {
    window.sessionStorage.setItem("dismissedAwayModal", true);
    this.setState({ dismissedModal: true });
  };

  _handleOpenModal = () => {
    this.setState({ dismissedModal: false });
  };

  render() {
    const { classes } = this.props;
    const { dismissedModal } = this.state;

    const open = !dismissedModal && navigator.userAgent != "ReactSnap";

    const now = new Date();
    if (now < LeaveDate || now > ReturnDate) return <div />;

    return (
      <div className={classes.root}>
        <Container>
          <Card elevation={8} className={classes.card}>
            <CardContent className={classes.cardContent}>
              <Row>
                <Col xs={8} alignItems="center" alignContent="center" container>
                  <Text paragraph={false} variant="caption">
                    JA Lactation is out of the office until{" "}
                    <b>
                      {shortDate(ReturnDate)}
                      th
                    </b>
                    .
                  </Text>
                </Col>
                <Col xs={4} justify="flex-end" container>
                  <Button
                    variant="contained"
                    raised={false}
                    size="small"
                    onClick={this._handleOpenModal}
                  >
                    Info
                  </Button>
                </Col>
              </Row>
            </CardContent>
          </Card>
        </Container>
        <Dialog
          open={open}
          onClose={this._handleCloseModal}
          TransitionComponent={Transition}
        >
          <DialogContent>
            <DialogContentText paragraph variant="body2">
              Congratulations on your new baby! JA Lactation is out of the
              office until{" "}
              <b>
                {shortDate(ReturnDate)}
                th
              </b>
              .
            </DialogContentText>

            <DialogContentText paragraph variant="body2">
              In the meantime, we invite you to { ' ' }
              <Link to="/classes-and-events#breastfeeding-support-groups">
                join us Tuesdays
              </Link>
              { ' ' }
              from 9:00am to 10:00am at Harveston Lake playground (off Balboa drive in Temecula).
              It's a great opportunity to connect with others and learn more about infant feeding.
            </DialogContentText>

            <DialogContentText paragraph variant="body2">
              If you need immediate assistance, we recommend reaching out to these International
              Board Certified Lactation Consultants. They're dedicated professionals who would love
              to help you with any infant feeding concerns:
            </DialogContentText>
            <DialogContentText paragraph variant="body2">
              <b>Rachelle Markham, IBCLC, RLC</b>
              <br />
              <a
                href="https://wellnesslactationsd.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                wellnesslactationsd.com
              </a>
              <br />
            </DialogContentText>
            <DialogContentText paragraph variant="body2">
              <b>Kristen Davis, IBCLC</b>
              <br />
              <a
                href="https://breast4baby.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Breast4Baby.com
              </a>
              <br />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={this._handleCloseModal}>
              Thanks
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withStyles(styleSheet)(AwayNotice);
