import React from "react"
import withStyles from "@material-ui/core/styles/withStyles"
import Helmet from "react-helmet"

import MainAppChrome from "../../chrome/MainAppChrome"
import Hero from "../../../components/ui-Hero"
import HeroTitle from "../../../components/ui-HeroTitle"
import HaloPicture from "../../../components/HaloPicture"
import PageSection from "../../../components/ui-PageSection"
import { Container, Row, Col } from "Grid"
import { Text, TextList, Title, Header, Headline } from "Text"

import Button from "@material-ui/core/Button"

import BioSection from "../../BioSection"
import SupportGroupBanner from "../../SupportGroupBanner"
import ServicePaymentOptions from "../../ServicePaymentOptions"
import ServiceCallInstructions from "../../ServiceCallInstructions"
import StructuredData from "../../../components/StructuredData"

import { SCHEDULE_URL, ASK_URL } from "../../../lib/urls"

import breastfeedingHaloImage from "../../../../static/images/jump-start-halo.jpg"

const styleSheet = theme => {
  return {
    heroPicture: {
      margin: [0, theme.spacing(5)],

      [theme.breakpoints.down("xs")]: {
        margin: theme.spacing(3),
      },

      [theme.breakpoints.down("sm")]: {
        maxWidth: theme.spacing(44),
      },
    },

    scheduleButton: {
      margin: theme.spacing(),

      "&:first-child": {
        marginLeft: 0,
      },

      [theme.breakpoints.down("xs")]: {
        display: "block",
        flex: [1, 0, "100%"],
      },
    },

    scheduleSection: {
      marginTop: theme.spacing(3),
      flexDirection: "row",
      flexWrap: "wrap",
      alignItems: "center",
    },

    pricingWell: {
      marginTop: theme.spacing(5),
    },
  }
}

const structuredData = {
  "@context": "http://schema.org/",
  "@type": "Service",
  name: "Postpartum Consultation",
  description:
    "This is a 60-90 minute in home consultation for mothers (and their partners) that need breastfeeding guidance. You may feel you lack milk, have pain when nursing, or face other challenges; I can help.",
  offers: {
    "@type": "Offer",
    priceCurrency: "USD",
    price: "165.00",
    url: "https://jalactation.com/jump-start-breastfeeding-class",
  },
}

function PostpartumConsultation({ classes }) {
  return (
    <MainAppChrome>
      <Helmet>
        <title>Jump Start Breastfeeding Class</title>
        <meta
          name="description"
          content="
              This is a 60-90 minute in home or in office consultation for
              mothers (and their partners) that need breastfeeding guidance.
              You may feel you lack milk, have pain when nursing, or face other
              challenges; I can help."
        />
      </Helmet>
      <Hero>
        <Container wide>
          <Row alignItems="center">
            <Col sm={12} md={5} lg={4}>
              <HaloPicture image={breastfeedingHaloImage} className={classes.heroPicture} />
            </Col>
            <Col xs={12} md={7} lg={8}>
              <HeroTitle
                title="Jump Start Breastfeeding Class"
                subTitle="
                  Families can be surprised at how difficult breastfeeding can be. Best
                  to be taken while you are pregnant, this class will give you a
                  jumpstart on your breastfeeding journey! Come (along with your
                  partner) and be motivated to start breastfeeding off right.
                "
              />

              <Container wide flex className={classes.scheduleSection}>
                <Button
                  color="primary"
                  variant="contained"
                  href="/booking/jump-start"
                  className={classes.scheduleButton}
                >
                  Join a class
                </Button>
                <Button color="primary" href={ASK_URL} className={classes.scheduleButton}>
                  Ask a question
                </Button>
                <Button color="primary" href="#pricing" className={classes.scheduleButton}>
                  Pricing
                </Button>
                <StructuredData data={structuredData} />
              </Container>
            </Col>
          </Row>
        </Container>
      </Hero>

      <PageSection gutterTop gutterBottom>
        <Container wide>
          <Row>
            <Col md={7} xs={12}>
              <Headline paragraph>What is covered during the class?</Headline>

              <TextList>
                <li>
                  How to avoid breastfeeding challenges such as sore nipples, engorgement, etc.
                </li>
                <li>Infant feeding cues</li>
                <li>Best breastfeeding positions</li>
                <li>Effective latch techniques</li>
                <li>How to know your baby is getting enough</li>
                <li>The importance of skin to skin</li>
              </TextList>

              <div className={classes.pricingWell} id="pricing">
                <Title paragraph>Pricing</Title>
                <Row>
                  <Col>
                    <Header paragraph>
                      $60 At <a href="/contact">My Office</a>
                    </Header>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={1} xs={12} />
            <Col lg={3} md={4} xs={12}>
              <ServiceCallInstructions />
            </Col>
          </Row>
        </Container>
      </PageSection>
      <BioSection />
      <SupportGroupBanner />
    </MainAppChrome>
  )
}

export default withStyles(styleSheet)(PostpartumConsultation)
