import React from "react"
import withStyles from "@material-ui/core/styles/withStyles"
import Helmet from "react-helmet"

import { Link } from "react-router-dom"

import MainAppChrome from "../../chrome/MainAppChrome"
import Hero from "../../../components/ui-Hero"
import HeroTitle from "../../../components/ui-HeroTitle"
import HaloPicture from "../../../components/HaloPicture"
import PageSection from "../../../components/ui-PageSection"
import { Container, Row, Col } from "Grid"
import { Text, TextList, Title, Header, Headline } from "Text"

import Button from "@material-ui/core/Button"

import BioSection from "../../BioSection"
import SupportGroupBanner from "../../SupportGroupBanner"
import ServicePaymentOptions from "../../ServicePaymentOptions"
import ServiceCallInstructions from "../../ServiceCallInstructions"
import StructuredData from "../../../components/StructuredData"

import { SCHEDULE_URL, ASK_URL } from "../../../lib/urls"

import breastfeedingHaloImage from "../../../../static/images/jump-start-halo.jpg"

const styleSheet = theme => {
  return {
    heroPicture: {
      margin: [0, theme.spacing(5)],

      [theme.breakpoints.down("xs")]: {
        margin: theme.spacing(3),
      },

      [theme.breakpoints.down("sm")]: {
        maxWidth: theme.spacing(44),
      },
    },

    scheduleButton: {
      margin: theme.spacing(),

      "&:first-child": {
        marginLeft: 0,
      },

      [theme.breakpoints.down("xs")]: {
        display: "block",
        flex: [1, 0, "100%"],
      },
    },

    scheduleSection: {
      marginTop: theme.spacing(3),
      flexDirection: "row",
      flexWrap: "wrap",
      alignItems: "center",
    },

    pricingWell: {
      marginTop: theme.spacing(5),
    },
  }
}

const structuredData = {
  "@context": "http://schema.org/",
  "@type": "Service",
  name: "Pumping Consultation",
  description:
    "Finding the correct pump flange size will allow more comfort and better milk expression while pumping.",
  offers: {
    "@type": "Offer",
    priceCurrency: "USD",
    price: "125.00",
    url: "https://jalactation.com/pumping-support/pumping-consultation",
  },
}

function PumpingConsultation({ classes }) {
  return (
    <MainAppChrome>
      <Helmet>
        <title>Pumping Consultation</title>
        <meta
          name="description"
          content="Finding the correct pump flange size will allow more comfort and better milk expression while pumping."
        />
      </Helmet>
      <Hero>
        <Container wide>
          <Row alignItems="center">
            <Col sm={12} md={5} lg={4}>
              <HaloPicture image={breastfeedingHaloImage} className={classes.heroPicture} />
            </Col>
            <Col xs={12} md={7} lg={8}>
              <HeroTitle
                title="Pumping Consultation"
                subTitle={
                  <>
                    <p>
                      Are you feeling anxious about pumping when you head back to work? Would you like guidance on using your pump effectively? Are you looking for ways to boost milk supply?
                    </p>
                    <p>
                      Our 60-minute pumping consultation is here for those who are preparing for the transition back to work, facing time away from their baby, or looking to enhance their milk production.
                    </p>
                  </>
                }

              />

              <Container wide flex className={classes.scheduleSection}>
                <Button
                  color="primary"
                  variant="contained"
                  href={SCHEDULE_URL}
                  target="_blank"
                  className={classes.scheduleButton}
                >
                  Schedule a consultation
                </Button>
                <Button color="primary" href={ASK_URL} className={classes.scheduleButton}>
                  Ask a question
                </Button>
                <Button color="primary" href="#pricing" className={classes.scheduleButton}>
                  Pricing
                </Button>
                <StructuredData data={structuredData} />
              </Container>
            </Col>
          </Row>
        </Container>
      </Hero>

      <PageSection gutterTop gutterBottom>
        <Container wide>
          <Row>
            <Col md={7} xs={12}>
              <Headline paragraph>What to expect during your consultaiton</Headline>

              <TextList>
                <li>Flange fitting to ensure correct size</li>
                <li>Discuss and Demonstrate your specific pump and how to use it effectively</li>
                <li>You'll receive a personalized plan of care that will outline how often and for how long to pump along with tips on storing milk for your baby.</li>
              </TextList>

              <div className={classes.pricingWell} id="pricing">
                <Title paragraph>Pricing</Title>
                <Row>
                  <Col>
                    <Header paragraph>
                      $125 At <a href="/contact">My Office</a>
                    </Header>
                  </Col>
                </Row>

                <ServicePaymentOptions />
              </div>
            </Col>
            <Col md={1} xs={12} />
            <Col lg={3} md={4} xs={12}>
              <ServiceCallInstructions />
            </Col>
          </Row>
        </Container>
      </PageSection>
      <BioSection />
      <SupportGroupBanner />
    </MainAppChrome>
  )
}

export default withStyles(styleSheet)(PumpingConsultation)
