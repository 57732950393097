import React from "react"
import withStyles from "@material-ui/core/styles/withStyles"

import { Container } from "Grid"
import { Title, Text } from "Text"

import Button from "@material-ui/core/Button"

import { Link } from "react-router-dom"

import tileImage from "../../../static/images/flower-tile.jpg"

const styleSheet = theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    backgroundImage: `url(${tileImage})`,
    backgroundSize: ["auto", theme.spacing(24)],
    backgroundRepeat: "repeat-x",
    padding: [theme.spacing(3), 0],
  },

  card: {
    display: "flex",
    margin: "auto",
    maxWidth: "7in",
    alignItems: "center",
    borderRadius: theme.spacing(),
    background: theme.palette.background.paper,
    boxShadow: theme.shadows[2],
    padding: [theme.spacing(2), theme.spacing(3)],

    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },

  buttonColumn: {
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(2),
    },

    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(2),
    },
  },
})

function SupportGroupBanner({ classes }) {
  return (
    <div className={classes.root}>
      <Container color="none" wide>
        <div className={classes.card}>
          <div>
            <Title color="inherit" gutterBottom>
              Infant Feeding Support Group
            </Title>
            <Text color="inherit" paragraph={false}>
              Join JA Lactaton Support Tuesday from 9:00am to 10:00am at Harveston Lake (by the
              playground).
            </Text>
          </div>
          <div className={classes.buttonColumn}>
            <Button
              component={Link}
              variant="outlined"
              to="/classes-and-events#breastfeeding-support-groups"
            >
              Learn more
            </Button>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default withStyles(styleSheet)(SupportGroupBanner)
