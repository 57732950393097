import React from "react"
import PropTypes from "prop-types"
import withStyles from "@material-ui/core/styles/withStyles"

import { Link } from "react-router-dom"

import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import CardMedia from "@material-ui/core/CardMedia"
import ButtonBase from "@material-ui/core/ButtonBase"
import { Title, Text } from "Text"

const styleSheet = theme => ({
  root: {
    height: "100%",

    [theme.breakpoints.up("sm")]: {
      transition: theme.transitions.create("box-shadow"),

      "&:hover": {
        boxShadow: theme.shadows[8],
      },
    },
  },

  cardButton: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    padding: 0,
    textTransform: "none",
  },

  linkButton: {
    ...theme.typography.button,
    color: theme.palette.secondary.main,
  },

  copyContent: {
    flex: 1,
    width: "100%",
    padding: [theme.spacing(2), theme.spacing(3)],
  },

  linkContent: {
    width: "100%",
    padding: [0, theme.spacing(3), theme.spacing(3), theme.spacing(3)],
  },

  media: {
    height: 100,
    width: "100%",
    backgroundColor: theme.palette.background.default,
    backgroundSize: "cover",
  },
})

function LinkCard({
  children,
  classes,
  href,
  image,
  linkLabel,
  rel,
  target,
  title,
  to,
  ...otherProps
}) {
  return (
    <Card className={classes.root} {...otherProps}>
      <ButtonBase
        component={href ? "a" : Link}
        to={to}
        href={href}
        rel={rel}
        target={target}
        className={classes.cardButton}
      >
        {image && <CardMedia image={image} className={classes.media} />}
        <CardContent className={classes.copyContent}>
          <Title gutterBottom>{title}</Title>
          <Text paragraph={false} color="textSecondary">
            {children}
          </Text>
        </CardContent>
        <CardContent className={classes.linkContent}>
          <div className={classes.linkButton}>{linkLabel || "Learn More"}</div>
        </CardContent>
      </ButtonBase>
    </Card>
  )
}

LinkCard.propTypes = {
  href: PropTypes.string,
  image: PropTypes.string,
  linkLabel: PropTypes.string,
  rel: PropTypes.string,
  target: PropTypes.string,
  title: PropTypes.string,
  to: PropTypes.string,
}

export default withStyles(styleSheet)(LinkCard)
