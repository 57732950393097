import React from "react"
import PropTypes from "prop-types"
import withStyles from "@material-ui/core/styles/withStyles"

import ScrollFrame from "ScrollFrame"
import ScrollFrameBody from "ScrollFrameBody"
import ScrollFrameHeader from "ScrollFrameHeader"
import MainAppDrawer from "../MainAppDrawer"
import MainAppBar from "MainAppBar"
import Button from "@material-ui/core/Button"
import IconButton from "@material-ui/core/IconButton"
import Icon from "@material-ui/core/Icon"
import { Link } from "react-router-dom"
import Hidden from "@material-ui/core/Hidden"

import FooterContact from "./FooterContact"
import FooterLegal from "./FooterLegal"

import AwayNotice from "../../AwayNotice"

import { SCHEDULE_URL } from "../../../lib/urls"
import signatureSvg from "../../../../static/images/signature.svg"

const styleSheet = theme => ({
  logoLink: {
    textDecoration: "none",
    position: "relative",
  },

  logo: {
    height: theme.spacing(7),
    margin: [theme.spacing(2), 0],
  },

  logoTitle: {
    ...theme.typography.body1,
    color: "inherit",
    margin: 0,
    position: "absolute",
    top: theme.spacing(8),
    left: theme.spacing(2),
    fontSize: "0.8em",
    whiteSpace: "nowrap",
  },
})

class MainAppChrome extends React.Component {
  state = {
    menuOpen: false,
  }

  showMobileMenu = () => this.setState({ menuOpen: true })
  hideMobileMenu = () => this.setState({ menuOpen: false })

  render() {
    const { children, classes, drawer } = this.props
    const drawerElement =
      drawer &&
      React.cloneElement(drawer, { open: this.state.menuOpen, onClose: this.hideMobileMenu })

    return (
      <ScrollFrame>
        <ScrollFrameHeader>
          <MainAppBar
            wide
            title={
              <Link to="/" className={classes.logoLink}>
                <img
                  src={signatureSvg}
                  alt="Julie Alexander Lactation Support Logo"
                  className={classes.logo}
                />
                <div className={classes.logoTitle}>JA Lactation Support</div>
              </Link>
            }
            color="inherit"
          >
            <Hidden xsDown>
              <Button
                color="secondary"
                variant="outlined"
                href={SCHEDULE_URL}
                target="_blank"
                rel="noopener noreferrer"
              >
                Schedule a consultation
              </Button>
            </Hidden>
            <Hidden smUp>
              <IconButton onClick={this.showMobileMenu}>
                <Icon size="sm">menu</Icon>
              </IconButton>
            </Hidden>
          </MainAppBar>
        </ScrollFrameHeader>
        <ScrollFrameBody flex>
          {children}
          <AwayNotice />
          <FooterContact />
          <FooterLegal />
        </ScrollFrameBody>
        {drawerElement}
      </ScrollFrame>
    )
  }
}

MainAppChrome.propTypes = {
  drawer: PropTypes.node,
}

MainAppChrome.defaultProps = {
  drawer: <MainAppDrawer />,
}

export default withStyles(styleSheet)(MainAppChrome)
