import React from "react"
import withStyles from "@material-ui/core/styles/withStyles"

import { Link } from "react-router-dom"

import { Header } from "Text"
import { Container, Row, Col } from "Grid"

import SocialLink from "ui-SocialLink"

const styleSheet = theme => ({
  root: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.getContrastText(theme.palette.secondary.main),
    padding: [theme.spacing(4), 0],

    "& a": {
      color: "inherit",
    },
  },

  logo: {
    height: theme.spacing(8),
  },

  socialLink: {
    margin: [0, theme.spacing(2), theme.spacing(2), 0],
  },

  socialLinks: {
    [theme.breakpoints.up("md")]: {
      display: "flex",
      justifyContent: "flex-end",
    },
  },

  linkList: {
    ...theme.typography.caption,
    color: "inherit",
    listStyle: "none",
    padding: "0",

    "& > li": {
      margin: [theme.spacing(2), 0],
    },

    "& a": {
      textDecoration: "none",
    },
  },

  [theme.breakpoints.down("sm")]: {
    logoCol: {
      order: 1,
    },

    socialLinksCol: {
      order: 1,
    },

    linksCol: {
      order: 2,
    },
  },
})

function FooterContact({ classes }) {
  return (
    <nav className={classes.root}>
      <Container wide>
        <Row>
          <Col xs={12} sm={8} md={3} className={classes.logoCol}>
            <img
              src={require("../../../../static/images/logo-inverse.svg")}
              className={classes.logo}
              alt="JA Lactation Support Logo"
            />
          </Col>
          <Col xs={12} sm={12} md={6} className={classes.linksCol}>
            <Row gutterBottom>
              <Col xs={12} sm>
                <Header color="inherit">Services</Header>
                <ul className={classes.linkList}>
                  <li>
                    <Link to="/breastfeeding-support/consultation">Breastfeeding Consultation</Link>
                  </li>
                  <li>
                    <Link to="/breastfeeding-support/prenatal-consultation">
                      Prenatal Consultation
                    </Link>
                  </li>
                  <li>
                    <Link to="/pumping-support/flange-fitting">
                      Flange Fitting
                    </Link>
                  </li>
                  <li>
                    <Link to="/pumping-support/pumping-consultation">
                      Pumping Consultation
                    </Link>
                  </li>
                  <li>
                    <Link to="/classes-and-events">Classes and Events</Link>
                  </li>
                </ul>
              </Col>
              <Col xs={12} sm>
                <Header color="inherit">Legal</Header>
                <ul className={classes.linkList}>
                  <li>
                    <Link to="/forms">Forms</Link>
                  </li>
                  <li>
                    <a href="/forms/consent.pdf" download>
                      Consent Form
                    </a>
                  </li>
                  <li>
                    <a href="/forms/hipaa.pdf" download>
                      HIPAA Notice
                    </a>
                  </li>
                </ul>
              </Col>
              <Col xs={12} sm>
                <Header color="inherit">Support</Header>
                <ul className={classes.linkList}>
                  <li>
                    <Link to="/contact">Contact</Link>
                  </li>
                  <li>
                    <a href="mailto:julie@jalactation.com">julie@jalactation.com</a>
                  </li>
                  <li>
                    <a href="tel:9515266870">951-526-6870</a>
                  </li>
                </ul>
              </Col>
            </Row>
          </Col>
          <Col xs={12} sm={4} md={3} className={classes.socialLinksCol}>
            <div className={classes.socialLinks}>
              <SocialLink network="facebook" className={classes.socialLink} />
              <SocialLink network="instagram" className={classes.socialLink} />
            </div>
          </Col>
        </Row>
      </Container>
    </nav>
  )
}

export default withStyles(styleSheet)(FooterContact)
