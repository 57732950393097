import React from "react"
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom"
import GoogleAnalyticsNavigation from "components/GoogleAnalyticsNavigation"

import Landing from "./Landing"
import LactationServices from "./LactationServices"
import Missing from "./Errors/Missing"
import PostpartumConsultation from "./PostpartumConsultation"
import PrenatalConsultation from "./PrenatalConsultation"
import FlangeFitting from "./FlangeFitting"
import PumpingConsultation from "./PumpingConsultation"
import LossConsultation from "./LossConsultation"
import Forms from "./Forms"
import Contact from "./Contact"
import Terms from "./Terms"
import Privacy from "./Privacy"
import Booking from "./Booking"
import JumpStart from "./Booking/JumpStart"

import ClassesAndEvents from "./ClassesAndEvents"
import JumpStartClass from "./JumpStartClass"

import "base.css"

global.intakeq = "5f2a4a4395575d2d503e35a3"

export default function AppRoot() {
  return (
    <React.Fragment>
      <Router>
        <GoogleAnalyticsNavigation>
          <Switch>
            <Route path="/breastfeeding-support/consultation" component={PostpartumConsultation} />
            <Route path="/breastfeeding-support/in-home-consultation">
              <Redirect to="/breastfeeding-support/consultation" />
            </Route>
            <Route
              path="/breastfeeding-support/prenatal-consultation"
              component={PrenatalConsultation}
            />
            <Route path="/flange-fitting">
              <Redirect to="/pumping-support/flange-fitting" />
            </Route>
            <Route path="/pumping-support/flange-fitting" component={FlangeFitting} exact />
            <Route
              path="/pumping-support/pumping-consultation"
              component={PumpingConsultation}
              exact
            />
            <Route path="/pumping-support/loss-consultation" component={LossConsultation} exact />
            <Route path="/breastfeeding-support" component={LactationServices} exact />
            <Route path="/pumping-support" component={LactationServices} exact />

            <Route path="/classes-and-events" component={ClassesAndEvents} exact />
            <Route path="/jump-start-breastfeeding-class" component={JumpStartClass} exact />

            <Route path="/forms" component={Forms} exact />
            <Route path="/contact" component={Contact} />

            <Route path="/terms" component={Terms} />
            <Route path="/privacy" component={Privacy} />
            <Route path="/booking/jump-start" component={JumpStart} />

            <Route path="/" component={Landing} exact />
            <Route component={Missing} />
          </Switch>
        </GoogleAnalyticsNavigation>
      </Router>
    </React.Fragment>
  )
}
