import React from "react"
import PropTypes from "prop-types"

import compose from "recompose/compose"
import withMobileMaxHeight from "withMobileMaxHeight"
import withStyles from "@material-ui/core/styles/withStyles"
import classnames from "classnames"

const styleSheet = theme => ({
  root: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    background: theme.palette.background.workspace,
    minHeight: "100vh",
    maxHeight: "100%",
    overflow: "scroll",

    "&:before, &:after": {
      content: "''",
      flexGrow: 1,
      display: "block",

      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },

    [theme.breakpoints.down("xs")]: {
      height: "100vh",
      maxHeight: "100vh",
      background: theme.palette.background.paper,
    },
  },

  "size--xs": {
    "& $container": {
      width: 320 + theme.spacing(8),
    },
  },

  "size--sm": {
    "& $container": {
      width: 450 + theme.spacing(8),
    },
  },

  "size--md": {
    "& $container": {
      width: theme.breakpoints.width("sm") + theme.spacing(8),
    },
  },

  "size--lg": {
    "& $container": {
      width: theme.breakpoints.width("md") + theme.spacing(5),
    },
  },

  container: {
    display: "flex",
    flexDirection: "column",
    flex: [0, 1, "auto"],
    maxWidth: "100%",
    margin: [0, "auto"],
    padding: theme.spacing(4),
    alignItems: "center",

    [theme.breakpoints.down("xs")]: {
      "div&&": {
        width: "100%",
        flex: 1,
        padding: 0,
      },
    },
  },
})

function StaticDialogContainer({
  children,
  classes,
  className: classNameProp,
  elementRef,
  size,

  ...otherProps
}) {
  const className = classnames(classes.root, classes[`size--${size}`], classNameProp)

  return (
    <div className={className} ref={elementRef} {...otherProps}>
      <div className={classes.container}>{children}</div>
    </div>
  )
}

StaticDialogContainer.propTypes = {
  size: PropTypes.oneOf(["xs", "sm", "md", "lg"]),
  elementRef: PropTypes.func,
}

StaticDialogContainer.defaultProps = {
  size: "sm",
}

export default compose(withMobileMaxHeight(), withStyles(styleSheet))(StaticDialogContainer)
