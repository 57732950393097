import React from "react"
import withStyles from "@material-ui/core/styles/withStyles"

import { Container, Row, Col } from "Grid"
import LinkCard from "../../../components/ui-LinkCard"

import breastfeedingImage from "../../../../static/images/breastfeeding-card-hero.jpg"
import prenatalImage from "../../../../static/images/prenatal-card-hero.jpg"
import breastfeedingClassImage from "../../../../static/images/breastfeeding-class-card-hero.jpg"

const styleSheet = theme => ({
  root: {
    padding: [theme.spacing(2), 0, theme.spacing(4)],
    background: theme.palette.background.paper,

    [theme.breakpoints.up("sm")]: {
      padding: [theme.spacing(6), 0, theme.spacing(4)],
    },
  },
})

function Services({ classes }) {
  return (
    <div className={classes.root}>
      <Container wide>
        <Row spacing={3} alignItems="stretch">
          <Col xs={12} sm={4}>
            <LinkCard
              to="/breastfeeding-support/consultation"
              title="Infant Feeding Consultation"
              linkLabel="Get help breastfeeding"
              image={breastfeedingImage}
            >
              You may feel you lack milk, have pain when nursing, difficulty latching, limited
              weight gain or face other challenges; I can help.
            </LinkCard>
          </Col>
          <Col xs={12} sm={4}>
            <LinkCard
              to="/breastfeeding-support/prenatal-consultation"
              title="Pre-Baby Consultation"
              linkLabel="Prenatal options"
              image={prenatalImage}
            >
              For families that have questions about infant feeding, lactation, or a history of
              complications when breastfeeding
            </LinkCard>
          </Col>
          <Col xs={12} sm={4}>
            <LinkCard
              to="/jump-start-breastfeeding-class"
              title="Jump Start Infant Feeding Class"
              linkLabel="Class details"
              image={breastfeedingClassImage}
            >
              Best to be taken while you are pregnant, this class will give you a jumpstart on your
              infant feeding journey!
            </LinkCard>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default withStyles(styleSheet)(Services)
