import React from "react"
import withStyles from "@material-ui/core/styles/withStyles"

import { Container, Row, Col } from "Grid"
import { Title } from "Text"
import Button from "@material-ui/core/Button"
import Icon from "@material-ui/core/Icon"

const styleSheet = theme => ({
  root: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },

  icon: {
    "&&": {
      fontSize: theme.spacing(12),
      lineHeight: 1,
      color: theme.palette.action.disabled,
    },
  },

  form: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },

  formTitle: {
    margin: [theme.spacing(3), 0],
    textAlign: "center",
  },
})

function Forms({ classes }) {
  return (
    <div className={classes.root}>
      <Container narrow>
        <Row gutterTop gutterBottom alignItems="center" justify="center">
          <Col xs={12} sm={4}>
            <div className={classes.form}>
              <Icon className={classes.icon}>done_all</Icon>
              <Title className={classes.formTitle}>Consent Form</Title>
              <Button href="/forms/consent.pdf" target="_blank" download variant="outlined">
                Download &amp; sign
              </Button>
            </div>
          </Col>
          <Col xs={12} sm={4}>
            <div className={classes.form}>
              <Icon className={classes.icon}>verified_user</Icon>
              <Title className={classes.formTitle}>HIPAA Notice of Privacy Practices</Title>
              <Button href="/forms/hipaa.pdf" target="_blank" download variant="outlined">
                Download
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default withStyles(styleSheet)(Forms)
