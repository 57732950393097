import React from "react"

import { makeStyles } from "@material-ui/core/styles"
import { fade } from "@material-ui/core/styles/colorManipulator"

import classnames from "classnames"

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: "50%",
    overflow: "hidden",
    position: "relative",

    "& img": {
      maxWidth: "100%",
      display: "block",
    },

    "&:after": {
      display: "block",
      content: "' '",
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      borderRadius: "50%",
      boxShadow: ["inset", 0, 0, 0, theme.spacing(2), fade(theme.palette.common.white, 0.5)],
    },
  },
}))

export default function HaloPicture({ image, alt, className: classNameProp, ...props }) {
  const styles = useStyles()
  const className = classnames(styles.root, classNameProp)

  return (
    <div className={className} {...props}>
      <img src={image} alt={alt} />
    </div>
  )
}
