import React from "react"
import withStyles from "@material-ui/core/styles/withStyles"

import { Text } from "Text"

import storyBackgroundMobile from "../../../../static/images/story-background-mobile.jpg"
import storyBackground from "../../../../static/images/story-background.jpg"

const styleSheet = theme => ({
  root: {
    position: "relative",
  },

  background: {
    background: [`url(${storyBackgroundMobile})`, "50%", "0"],
    backgroundSize: "cover",
    position: "relative",
    height: "40vh",

    [theme.breakpoints.up("sm")]: {
      height: "50vh",
      backgroundImage: `url(${storyBackground})`,
    },
  },

  pullQuote: {
    padding: theme.spacing(3),
    background: theme.palette.secondary.main,
    color: theme.palette.getContrastText(theme.palette.secondary.main),
    fontWeight: theme.typography.fontWeightLight,

    [theme.breakpoints.up("sm")]: {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: 1.333,
      position: "absolute",
      bottom: "15%",
      left: "50%",
      width: "40vw",
      maxWidth: 600,
      borderRadius: theme.spacing(1 / 2),
      boxShadow: theme.shadows[4],
    },

    [theme.breakpoints.up("md")]: {
      fontSize: theme.typography.pxToRem(19),
    },

    [theme.breakpoints.up("lg")]: {
      fontSize: theme.typography.pxToRem(22),
    },
  },
})

function Hero({ classes }) {
  return (
    <div className={classes.root}>
      <div className={classes.background} />
      <Text paragraph={false} className={classes.pullQuote} component="div">
        I am an International Board Certified Lactation Consultant (IBCLC) and I will give you
        non-judgmental support to reach your breastfeeding goals. You will be confident in feeding
        your infant helping them to grow up happy and healthy.
      </Text>
    </div>
  )
}

export default withStyles(styleSheet)(Hero)
