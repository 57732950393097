import React from "react"
import PropTypes from "prop-types"
import withStyles from "@material-ui/core/styles/withStyles"
import classnames from "classnames"

const NETWORKS = {
  facebook: {
    href: "https://www.facebook.com/jalactation/",
    image: {
      white: require("../../../static/images/facebook-white.svg"),
      secondary: require("../../../static/images/facebook-ja.svg"),
    },
  },
  instagram: {
    href: "https://www.instagram.com/jalactation/",
    image: {
      white: require("../../../static/images/instagram-white.svg"),
      secondary: require("../../../static/images/instagram-ja.svg"),
    },
  },
  twitter: {
    href: "https://twitter.com/jalactation",
    image: {
      white: require("../../../static/images/twitter-white.svg"),
      secondary: require("../../../static/images/twitter-ja.svg"),
    },
  },
  google: {
    href: "https://plus.google.com/u/1/b/103439652334427877338",
    image: {
      white: require("../../../static/images/google-white.svg"),
      secondary: require("../../../static/images/google-ja.svg"),
    },
  },
  pinterest: {
    href: "https://www.pinterest.com/jalactation/",
    image: {
      white: require("../../../static/images/pinterest-white.svg"),
      secondary: require("../../../static/images/pinterest-ja.svg"),
    },
  },
}

const styleSheet = theme => ({
  root: {
    textDecoration: "none",
    display: "inline-block",
    overflow: "hidden",
    width: theme.spacing(4),
    height: theme.spacing(4),
  },

  image: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },

  colorInherit: {
    color: "inherit",
  },

  colorSecondary: {
    color: theme.palette.secondary.main,
  },
})

function SocialLink({ classes, className: classNameProp, color, network }) {
  const config = NETWORKS[network]
  if (!config) {
    return null
  }

  const { href, image } = NETWORKS[network]
  const className = classnames(
    classes.root,
    {
      [classes.colorInherit]: color === "inherit",
      [classes.colorSecondary]: color === "secondary",
    },
    classNameProp
  )

  return (
    <a href={href} target="_blank" rel="noopener noreferrer" className={className}>
      <img src={image[color]} className={classes.image} alt={`${network} Logo`} />
    </a>
  )
}

SocialLink.propTypes = {
  network: PropTypes.oneOf(Object.keys(NETWORKS)).isRequired,
  color: PropTypes.oneOf(["white", "secondary"]),
}

SocialLink.defaultProps = {
  color: "white",
}

export default withStyles(styleSheet)(SocialLink)
