import React from "react"
import withStyles from "@material-ui/core/styles/withStyles"
import classnames from "classnames"

import { Link } from "react-router-dom"

import Drawer from "@material-ui/core/Drawer"
import Divider from "@material-ui/core/Divider"

import ScrollFrame from "ScrollFrame"
import ScrollFrameBody from "ScrollFrameBody"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import ListSubheader from "@material-ui/core/ListSubheader"

const styleSheet = theme => ({
  paper: {
    maxWidth: "90vw",
    width: theme.spacing(32),
    height: "100%",
  },
})

class MainAppDrawer extends React.Component {
  render() {
    const {
      classes,
      className: classNameProp,

      ...otherProps
    } = this.props

    const className = classnames(classes.root, classNameProp)

    return (
      <Drawer
        className={className}
        classes={{ paper: classes.paper }}
        anchor="right"
        {...otherProps}
      >
        <ScrollFrame>
          <ScrollFrameBody>
            <List>
              <ListItem button to="/" component={Link}>
                <ListItemText primary="Home" />
              </ListItem>
            </List>
            <List subheader={<ListSubheader>Services</ListSubheader>}>
              <ListItem button to="/breastfeeding-support/in-home-consultation" component={Link}>
                <ListItemText primary="Breastfeeding Consultation" />
              </ListItem>
              <ListItem button to="/breastfeeding-support/prenatal-consultation" component={Link}>
                <ListItemText primary="Prenatal Consultation" />
              </ListItem>
              <ListItem button to="/jump-start-breastfeeding-class" component={Link}>
                <ListItemText primary="Jump Start Breastfeeding Class" />
              </ListItem>
              <ListItem button to="/pumping-support/flange-fitting" component={Link}>
                <ListItemText primary="Flange Fitting" />
              </ListItem>
              <ListItem button to="/pumping-support/pumping-consultation" component={Link}>
                <ListItemText primary="Pumping Consultation" />
              </ListItem>
              <ListItem button to="/pumping-support/loss-consultation" component={Link}>
                <ListItemText primary="Loss Consultation" />
              </ListItem>
            </List>
            <List subheader={<ListSubheader>More Links</ListSubheader>}>
              <ListItem button to="/forms" component={Link}>
                <ListItemText primary="Forms" />
              </ListItem>
              <ListItem button to="/contact" component={Link}>
                <ListItemText primary="Contact" />
              </ListItem>
            </List>
          </ScrollFrameBody>
          <Divider />
        </ScrollFrame>
      </Drawer>
    )
  }
}

export default withStyles(styleSheet)(MainAppDrawer)
