import React from "react"

import { Text, Header } from "Text"

export default function InsuranceNote() {
  return (
    <div id="service-payment-options">
      <Header paragraph>Payments &amp; Insurance</Header>
      <Text>
        I accept Venmo, Check, Cash or Credit Card. All consultations will include a superbill you
        can submit to your insurance provider for reimbursement.
      </Text>
    </div>
  )
}
