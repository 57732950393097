import React from "react"
import PropTypes from "prop-types"
import withStyles from "@material-ui/core/styles/withStyles"
import classnames from "classnames"

const styleSheet = theme => ({
  root: {
    display: "inline-block",
  },

  on: {
    color: theme.palette.secondary.main,
    "&:before": {
      content: "'★'",
    },
  },

  off: {
    color: theme.palette.action.disabled,
    "&:before": {
      content: "'☆'",
    },
  },
})

const renderStars = (count, className) =>
  Array.from(Array(count)).map((_, index) => <span key={index} className={className} />)

function Rating({ classes, className: classNameProp, value, range, ...otherProps }) {
  const className = classnames(classes.root, classNameProp)

  return (
    <span {...otherProps} className={className}>
      {renderStars(value, classes.on)}
      {renderStars(range - value, classes.off)}
    </span>
  )
}

Rating.propTypes = {
  value: PropTypes.number.isRequired,
  range: PropTypes.number.isRequired,
  className: PropTypes.string,
}

Rating.defaultProps = {
  range: 5,
}

export default withStyles(styleSheet)(Rating)
