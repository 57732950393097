import React from "react"
import withStyles from "@material-ui/core/styles/withStyles"
import Helmet from "react-helmet"

import { Link } from "react-router-dom"

import MainAppChrome from "../../chrome/MainAppChrome"
import Hero from "../../../components/ui-Hero"
import HeroTitle from "../../../components/ui-HeroTitle"
import HaloPicture from "../../../components/HaloPicture"
import PageSection from "../../../components/ui-PageSection"
import { Container, Row, Col } from "Grid"
import { Text, TextList, Title, Header, Headline } from "Text"

import Button from "@material-ui/core/Button"

import BioSection from "../../BioSection"
import SupportGroupBanner from "../../SupportGroupBanner"
import ServicePaymentOptions from "../../ServicePaymentOptions"
import ServiceCallInstructions from "../../ServiceCallInstructions"
import StructuredData from "../../../components/StructuredData"

import { SCHEDULE_URL, ASK_URL } from "../../../lib/urls"

import breastfeedingHaloImage from "../../../../static/images/jump-start-halo.jpg"

const styleSheet = theme => {
  return {
    heroPicture: {
      margin: [0, theme.spacing(5)],

      [theme.breakpoints.down("xs")]: {
        margin: theme.spacing(3),
      },

      [theme.breakpoints.down("sm")]: {
        maxWidth: theme.spacing(44),
      },
    },

    scheduleButton: {
      margin: theme.spacing(),

      "&:first-child": {
        marginLeft: 0,
      },

      [theme.breakpoints.down("xs")]: {
        display: "block",
        flex: [1, 0, "100%"],
      },
    },

    scheduleSection: {
      marginTop: theme.spacing(3),
      flexDirection: "row",
      flexWrap: "wrap",
      alignItems: "center",
    },

    pricingWell: {
      marginTop: theme.spacing(5),
    },
  }
}

const structuredData = {
  "@context": "http://schema.org/",
  "@type": "Service",
  name: "Flang Fitting",
  description:
    "Finding the correct pump flange size will allow more comfort and better milk expression while pumping.",
  offers: {
    "@type": "Offer",
    priceCurrency: "USD",
    price: "55.00",
    url: "https://jalactation.com/pumping-support/flange-fitting",
  },
}

function PostpartumConsultation({ classes }) {
  return (
    <MainAppChrome>
      <Helmet>
        <title>Flange Fitting</title>
        <meta
          name="description"
          content="Finding the correct pump flange size will allow more comfort and better milk expression while pumping."
        />
      </Helmet>
      <Hero>
        <Container wide>
          <Row alignItems="center">
            <Col sm={12} md={5} lg={4}>
              <HaloPicture image={breastfeedingHaloImage} className={classes.heroPicture} />
            </Col>
            <Col xs={12} md={7} lg={8}>
              <HeroTitle
                title="Flange Fitting"
                subTitle="Often times we begin pumping with too large of a flange and quickly feel discomfort or notice that we are not able to express much milk. When you're fitted with the right flange size you'll notice no discomfort and more milk output."
              />

              <Container wide flex className={classes.scheduleSection}>
                <Button
                  color="primary"
                  variant="contained"
                  href={SCHEDULE_URL}
                  target="_blank"
                  className={classes.scheduleButton}
                >
                  Schedule a consultation
                </Button>
                <Button color="primary" href={ASK_URL} className={classes.scheduleButton}>
                  Ask a question
                </Button>
                <Button color="primary" href="#pricing" className={classes.scheduleButton}>
                  Pricing
                </Button>
                <StructuredData data={structuredData} />
              </Container>
            </Col>
          </Row>
        </Container>
      </Hero>

      <PageSection gutterTop gutterBottom>
        <Container wide>
          <Row>
            <Col md={7} xs={12}>
              <Headline paragraph>What is covered during the fitting?</Headline>

              <TextList>
                <li>Work with your pump</li>
                <li>Discuss concerns with existing flange fit</li>
                <li>Try different flange sizes to determine what feels and works best for you</li>
              </TextList>

              <Text paragraph>
                Best for those who are already feeling confident pumping but need a better flange
                fit. For those that need help starting with their pumping journey a <Link to="/pumping-support/pumping-consultation">Feeding Consultation</Link> is the right option and includes a flange fitting.
              </Text>

              <div className={classes.pricingWell} id="pricing">
                <Title paragraph>Pricing</Title>
                <Row>
                  <Col>
                    <Header paragraph>
                      $55 At <a href="/contact">My Office</a>
                    </Header>
                  </Col>
                </Row>

                <ServicePaymentOptions />
              </div>
            </Col>
            <Col md={1} xs={12} />
            <Col lg={3} md={4} xs={12}>
              <ServiceCallInstructions />
            </Col>
          </Row>
        </Container>
      </PageSection>
      <BioSection />
      <SupportGroupBanner />
    </MainAppChrome>
  )
}

export default withStyles(styleSheet)(PostpartumConsultation)
