import React from "react"
import PropTypes from "prop-types"

import withStyles from "@material-ui/core/styles/withStyles"
import classnames from "classnames"

import ScrollFrameHeader from "ScrollFrameHeader"
import CardContent from "@material-ui/core/CardContent"

const styleSheet = theme => ({
  root: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(),

    [theme.breakpoints.down("xs")]: {
      paddingLeft: theme.spacing(),
      paddingRight: theme.spacing(),
    },
  },

  content: {
    "&:last-child": {
      paddingBottom: theme.spacing(2),
    },
  },

  gutterBottom: {
    paddingBottom: theme.spacing(3),
  },
})

function StaticDialogHeader({
  children,
  classes,
  className: classNameProp,
  gutterBottom,

  ...otherProps
}) {
  const className = classnames(
    classes.root,
    {
      [classes.gutterBottom]: gutterBottom,
    },
    classNameProp
  )

  return (
    <ScrollFrameHeader className={className} {...otherProps}>
      <CardContent className={classes.content}>{children}</CardContent>
    </ScrollFrameHeader>
  )
}

StaticDialogHeader.propTypes = {
  gutterBottom: PropTypes.bool,
}

export default withStyles(styleSheet)(StaticDialogHeader)
