import React from "react"
import PropTypes from "prop-types"
import Grid from "@material-ui/core/Grid"
import withStyles from "@material-ui/core/styles/withStyles"
import classnames from "classnames"

export const styleSheet = theme => ({
  gutterTop: { marginTop: theme.spacing(2) },
  gutterBottom: { marginBottom: theme.spacing(2) },
})

function Col({
  autoWidth,
  classes,
  className: classNameProp,
  gutterBottom,
  gutterTop,
  order,
  style,
  xs: xsProp,

  ...otherProps
}) {
  const xs = autoWidth && xsProp === undefined ? true : xsProp
  const pushPull = order ? { ...style, order } : style
  const className = classnames(
    classes.root,
    {
      [classes.gutterTop]: gutterTop,
      [classes.gutterBottom]: gutterBottom,
    },
    classNameProp
  )
  return <Grid item xs={xs} style={pushPull} className={className} {...otherProps} />
}

Col.propTypes = {
  autoWidth: PropTypes.bool,
  classes: PropTypes.object,
  gutterBottom: PropTypes.bool,
  gutterTop: PropTypes.bool,
  order: PropTypes.number,
  style: PropTypes.object,
  xs: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  otherBool: PropTypes.bool,
}

Col.defaultProps = {
  autoWidth: true,
  gutterTop: false,
  gutterBottom: false,
}

export default withStyles(styleSheet)(Col)
