import React from "react"
import PropTypes from "prop-types"

import { withStyles } from "@material-ui/core/styles"
import classnames from "classnames"

export const styleSheet = _theme => ({
  root: {
    flex: [0, 0, "auto"],
    position: "relative",
  },
})

function ScrollFrameHeader({
  classes,
  className: classNameProp,
  scrolled,

  ...otherProps
}) {
  const className = classnames(
    classes.root,
    {
      [classes.scrolled]: scrolled,
    },
    classNameProp
  )

  return <div {...otherProps} className={className} />
}

ScrollFrameHeader.propTypes = {
  scrolled: PropTypes.bool,
}

export default withStyles(styleSheet)(ScrollFrameHeader)
