import React from "react"
import withStyles from "@material-ui/core/styles/withStyles"
import Helmet from "react-helmet"

import MainAppChrome from "../../chrome/MainAppChrome"
import Hero from "../../../components/ui-Hero"
import PageSection from "../../../components/ui-PageSection"
import Divider from "Divider"
import { Container, Row, Col } from "Grid"
import { Headline, Text, Copy } from "Text"
import Button from "@material-ui/core/Button"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import CardMedia from "@material-ui/core/CardMedia"

const styleSheet = theme => ({
  map: {
    position: "absolute",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    width: "100%",
    height: "100%",
  },

  mapCard: {
    height: theme.spacing(6) * 3,
    position: "relative",

    [theme.breakpoints.up("sm")]: {
      height: theme.spacing(6) * 7,
    },
  },

  mapLabel: {
    [theme.breakpoints.up("sm")]: {
      height: theme.spacing(25),
    },
  },
})

function Contact({ classes }) {
  return (
    <MainAppChrome>
      <Helmet>
        <title>Contact JA Lactation Support</title>
      </Helmet>
      <PageSection>
        <Container wide>
          <Row className={classes.heroRow}>
            <Col className={classes.heroCol} xs={12} sm={6} gutterBottom>
              <Card>
                <CardMedia className={classes.mapCard}>
                  <iframe
                    title="map"
                    className={classes.map}
                    frameBorder={0}
                    src="https://www.google.com/maps/embed/v1/place?key=AIzaSyBoO-7FF1ybguzYbhO6BBz6tf-LQyPfKH8&q=JA+Lactation+Support,+IBCLC,Murietta+CA"
                  />
                </CardMedia>
                <CardContent className={classes.mapLabel}>
                  <Copy>
                    <Headline paragraph>Office Address</Headline>
                    <Text paragraph={false}>24977 Washington Ave Suite i</Text>
                    <Text paragraph>Murrieta, CA 92562 </Text>
                    <Button
                      href="https://goo.gl/maps/E5xTEBmxpEgwwjv1A"
                      target="_blank"
                      rel="nofollow noreferrer"
                      variant="outlined"
                    >
                      Get Directions
                    </Button>
                  </Copy>
                </CardContent>
              </Card>
            </Col>
            <Col className={classes.heroCol} xs={12} sm={6} gutterBottom>
              <Card>
                <CardMedia className={classes.mapCard}>
                  <iframe
                    title="map"
                    className={classes.map}
                    frameBorder={0}
                    src="https://www.google.com/maps/embed/v1/place?key=AIzaSyBoO-7FF1ybguzYbhO6BBz6tf-LQyPfKH8&q=JA+Lactation+Support,+IBCLC,Temecula+CA"
                  />
                </CardMedia>
                <CardContent className={classes.mapLabel}>
                  <Copy>
                    <Headline paragraph>Mailing Address</Headline>
                    <Text paragraph={false}>42274 Faber Ct </Text>
                    <Text paragraph={true}>Temecula, CA 92592</Text>
                    <Text paragraph={false} color="textSecondary">
                      Serving Temecula, Murrieta, Menifee, Fallbrook, Pala and surrounding areas.
                    </Text>
                  </Copy>
                </CardContent>
              </Card>
            </Col>
          </Row>
          <Divider gutterTop gutterBottom />
          <Copy>
            <Headline paragraph>Other</Headline>
            <Text paragraph>
              <a href="mailto:julie@jalactation.com">julie@jalactation.com</a>
            </Text>
            <Text paragraph>
              <a href="tel:9515266870">951-526-6870</a>
            </Text>
          </Copy>
        </Container>
      </PageSection>
    </MainAppChrome>
  )
}

export default withStyles(styleSheet)(Contact)
