import React, { Component } from "react"
import withStyles from "@material-ui/core/styles/withStyles"

import { Container, Row, Col } from "Grid"

import Testimony from "./Testimony"
import data from "./Data"

const styleSheet = theme => ({
  root: {
    background: theme.palette.background.paper,
    padding: [theme.spacing(8), 0],
  },
})

class Testimonials extends Component {
  state = { index: Math.floor(Math.random() * data.testimonials.length) }

  _rotateTestimony = () => {
    this.setState({ index: (this.state.index + 1) % data.testimonials.length })
  }

  render() {
    const { classes } = this.props

    return (
      <div className={classes.root}>
        <Container wide>
          <Row gutterBottom spacing={4}>
            <Col xs={12} sm={4}>
              <Testimony {...data.testimonials[this.state.index]} />
            </Col>
            <Col xs={12} sm={4}>
              <Testimony
                {...data.testimonials[(this.state.index + 1) % data.testimonials.length]}
              />
            </Col>
            <Col xs={12} sm={4}>
              <Testimony
                {...data.testimonials[(this.state.index + 2) % data.testimonials.length]}
              />
            </Col>
          </Row>
        </Container>
      </div>
    )
  }
}

export default withStyles(styleSheet)(Testimonials)
