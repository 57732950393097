import React from "react"
import PropTypes from "prop-types"
import Hidden from "@material-ui/core/Hidden"
import { Display, Headline, Title } from "Text"
import { Container } from "Grid"

export default function HeroTitle({ subTitle, title, wide, narrow }) {
  if (!title) {
    return null
  }

  return (
    <Container wide={wide} narrow={narrow} position="relative">
      <Hidden xsDown>
        <Display level={3} color="inherit" paragraph>
          {title}
        </Display>
        {subTitle && (
          <Headline paragraph component="div">
            {subTitle}
          </Headline>
        )}
      </Hidden>
      <Hidden smUp>
        <Display level={4} color="inherit" paragraph>
          {title}
        </Display>
        {subTitle && (
          <Title paragraph component="div">
            {subTitle}
          </Title>
        )}
      </Hidden>
    </Container>
  )
}

HeroTitle.propTypes = {
  title: PropTypes.string,
  subTitle: PropTypes.string,
  wide: PropTypes.bool,
  narrow: PropTypes.bool,
}

HeroTitle.defaultProps = {
  wide: true,
  narrow: false,
}
