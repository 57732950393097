import React from "react"
import PropTypes from "prop-types"
import MuiDivider from "@material-ui/core/Divider"

import withStyles from "@material-ui/core/styles/withStyles"
import classnames from "classnames"

const styleSheet = theme => ({
  inset: {
    marginLeft: theme.spacing(9),

    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(10),
    },
  },

  gutterTop: { marginTop: theme.spacing(3) },
  gutterBottom: { marginBottom: theme.spacing(3) },
})

function Divider({
  classes,
  className: classNameProp,
  gutterTop,
  gutterBottom,
  inset,

  ...otherProps
}) {
  const className = classnames(
    classes.root,
    {
      [classes.inset]: inset,
      [classes.gutterTop]: gutterTop,
      [classes.gutterBottom]: gutterBottom,
    },
    classNameProp
  )

  return <MuiDivider {...otherProps} className={className} />
}

Divider.propTypes = {
  gutterTop: PropTypes.bool,
  gutterBottom: PropTypes.bool,
  inset: PropTypes.bool,
}

export default withStyles(styleSheet)(Divider)
