import React from "react"
import Helmet from "react-helmet"

import Button from "@material-ui/core/Button"

import MainAppChrome from "../../chrome/MainAppChrome"
import Hero from "../../../components/ui-Hero"
import HeroTitle from "../../../components/ui-HeroTitle"
import PageSection from "../../../components/ui-PageSection"
import Divider from "Divider"
import { Container } from "Grid"
import { Headline, Copy } from "Text"

export default function LactationServices() {
  return (
    <MainAppChrome>
      <Helmet>
        <title>Forms</title>
      </Helmet>
      <Hero>
        <HeroTitle title="Forms" />
      </Hero>
      <PageSection gutterBottom>
        <Container wide>
          <Copy>
            <Headline paragraph>Consent Form</Headline>
            <Button
              variant="contained"
              color="default"
              component="a"
              href="/forms/consent.pdf"
              download
            >
              Download &amp; Sign
            </Button>
          </Copy>
          <Divider gutterTop gutterBottom />
          <Copy>
            <Headline paragraph>HIPAA Notice of Privacy Practices</Headline>
            <Button
              variant="contained"
              color="default"
              component="a"
              href="/forms/hipaa.pdf"
              download
            >
              Download
            </Button>
          </Copy>
          <Divider gutterTop gutterBottom />
          <Copy>
            <Headline paragraph>Initial Patient History</Headline>
            <Button
              variant="contained"
              color="default"
              component="a"
              href="https://goo.gl/forms/LcHlAZSWs9xy9ObX2"
              rel="noopener noreferrer"
              target="_blank"
            >
              Begin Survey
            </Button>
          </Copy>
        </Container>
      </PageSection>
    </MainAppChrome>
  )
}
