import React, { useEffect } from "react"
import withStyles from "@material-ui/core/styles/withStyles"
import Helmet from "react-helmet"

import MainAppChrome from "../../chrome/MainAppChrome"
import Hero from "../../../components/ui-Hero"
import HeroTitle from "../../../components/ui-HeroTitle"
import HaloPicture from "../../../components/HaloPicture"
import { Container, Row, Col } from "Grid"
import { Text, TextList, Title, Header, Headline } from "Text"
import Divider from "Divider"
import CancellationPolicy from "../../CancellationPolicy"

const styleSheet = theme => ({
  form: {
    flex: "1 0 auto",
    minHeight: "50vh",
  },
})

function Booking({ serviceId, classes }) {
  return (
    <MainAppChrome>
      <Helmet>
        <title>Julie Alexander Lactation Support</title>
        <meta
          name="description"
          content="Non-judgmental support from an IBCLC certified lactation consultant to reach your lactation and breastfeeding goals. Be confident in feeding your infant helping them to grow up happy and healthy."
        />
        <script>{`
  window.intakeq = "5f2a4a4395575d2d503e35a3";
  window.intakeqServiceId = "${serviceId}";
`}</script>
        {navigator.userAgent != "ReactSnap" && (
          <script src="https://intakeq.com/js/widget.min.js?1"></script>
        )}
      </Helmet>

      <div id="intakeq" className={classes.form}></div>
    </MainAppChrome>
  )
}

export default withStyles(styleSheet)(Booking)
