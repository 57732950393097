import React from "react"
import ReactDOM from "react-dom"

import Pages from "./views/pages"
import ErrorBoundary from "ErrorBoundary"

import Material from "Material"
import createTheme from "theme"

const theme = createTheme(document.dir, {
  settings: theme => {
    // Google SEO prefers 16px baseline.
    theme.typography = {
      ...theme.typography,
      fontSize: 16,
      display4: {
        fontFamily: `"Noto Serif", ${theme.typography.fontFamily}`,
      },
      display3: {
        fontFamily: `"Noto Serif", ${theme.typography.fontFamily}`,
      },
      display2: {
        fontFamily: `"Noto Serif", ${theme.typography.fontFamily}`,
      },
      display1: {
        fontFamily: `"Noto Serif", ${theme.typography.fontFamily}`,
      },
      headline: {
        fontFamily: `"Noto Serif", ${theme.typography.fontFamily}`,
      },
      title: {
        fontFamily: `"Noto Serif", ${theme.typography.fontFamily}`,
      },
    }

    theme.overrides = {
      ...theme.overrides,
      MuiPaper: {
        rounded: {
          borderRadius: 8,
        },
      },
    }

    return theme
  },
})

export const entrypointUrl = location.href

const rootEl = document.getElementById("root")

if (!window.navigator.standalone) {
  document.body.className = "browser"
}

// // Allow 'preload' stylesheets like fonts to load async for better performance.
// if (navigator.userAgent !== "ReactSnap") {
//   const stylesheets = document.getElementsByTagName("link")
//   Array.from(stylesheets).forEach(link => {
//     if (link.media === "preload") {
//       link.media = "all"
//     }
//   })
// }

const render = Component => {
  ReactDOM.render(
    <Material theme={theme}>
      <ErrorBoundary>
        <Component />
      </ErrorBoundary>
    </Material>,
    rootEl,
    () => {
      // https://github.com/stereobooster/react-snap/issues/99
      // Handle react-snap jss rehydration
      Array.from(document.querySelectorAll("[data-jss-snap]")).forEach(elem =>
        elem.parentNode.removeChild(elem)
      )
    }
  )
}

if (process.env.NODE_ENV !== "production") {
  if (module.hot) {
    module.hot.accept("./views/pages", () => {
      render(Pages)
    })
  }

  global.tapd = v => {
    debugger // eslint-disable-line no-debugger
    return v
  }
}

window.snapSaveState = () => {
  Array.from(document.querySelectorAll("[data-jss]")).forEach(elem =>
    elem.setAttribute("data-jss-snap", "")
  )

  return {}
}

render(Pages)
