import React from "react"
import PropTypes from "prop-types"
import withStyles from "@material-ui/core/styles/withStyles"
import classnames from "classnames"

const styleSheet = theme => ({
  root: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    flex: 1,
  },

  gutterTop: {
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(10),
    },
  },

  gutterBottom: {
    [theme.breakpoints.up("md")]: {
      paddingBottom: theme.spacing(10),
    },
  },

  colorPaper: {
    backgroundColor: theme.palette.background.paper,
  },
})

function PageSection({
  classes,
  className: classNameProp,
  children,
  color,
  gutterTop,
  gutterBottom,
}) {
  const className = classnames(
    classes.root,
    {
      [classes.colorPaper]: color === "paper",
      [classes.gutterTop]: gutterTop,
      [classes.gutterBottom]: gutterBottom,
    },
    classNameProp
  )

  return <div className={className}>{children}</div>
}

PageSection.propTypes = {
  color: PropTypes.oneOf(["default", "paper"]),
  gutterTop: PropTypes.bool,
  gutterBottom: PropTypes.bool,
}

PageSection.defaultProps = {
  color: "default",
}

export default withStyles(styleSheet)(PageSection)
