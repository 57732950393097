import React from "react"
import withStyles from "@material-ui/core/styles/withStyles"

import Button from "@material-ui/core/Button"
import { Container, Row, Col } from "Grid"
import { Text } from "Text"

import { SCHEDULE_URL } from "../../../lib/urls"

const styleSheet = theme => ({
  root: {
    background: theme.palette.background.paper,
    paddingBottom: theme.spacing(4),
  },

  button: {
    width: "100%",
  },

  serviceArea: {
    padding: [theme.spacing(2), 0],
  },
})

function ScheduleInline({ classes }) {
  return (
    <div className={classes.root}>
      <Container wide>
        <Row alignItems="center">
          <Col sm={1} />
          <Col xs={12} sm={4}>
            <Button
              color="primary"
              variant="contained"
              href={SCHEDULE_URL}
              target="_blank"
              size="large"
              className={classes.button}
            >
              Schedule a consultation
            </Button>
          </Col>
          <Col xs={12} sm={6}>
            <Text className={classes.serviceArea} paragraph={false} align="center">
              Serving Temecula, Murrieta, Menifee, Fallbrook, Pala and surrounding areas.
            </Text>
          </Col>
          <Col sm={1} />
        </Row>
      </Container>
    </div>
  )
}

export default withStyles(styleSheet)(ScheduleInline)
