import React from "react"
import PropTypes from "prop-types"

import withStyles from "@material-ui/core/styles/withStyles"
import classnames from "classnames"

const styleSheet = theme => ({
  root: {
    padding: [theme.spacing(3), 0, theme.spacing(3)],
    width: "100%",
    color: theme.palette.text.secondary,
    position: "absolute",
    top: "100%",

    [theme.breakpoints.down("xs")]: {
      padding: [theme.spacing(2), theme.spacing(3)],
      position: "static",
      marginTop: "auto",
    },
  },
})

function StaticDialogFootnote({
  children,
  classes,
  className: classNameProp,
  scrolled,

  ...otherProps
}) {
  const className = classnames(classes.root, classNameProp)

  return (
    <div className={className} {...otherProps}>
      {children}
    </div>
  )
}

StaticDialogFootnote.propTypes = {
  scrolled: PropTypes.bool,
}

export default withStyles(styleSheet)(StaticDialogFootnote)
