import React from "react"
import PropTypes from "prop-types"
import withStyles from "@material-ui/core/styles/withStyles"
import classnames from "classnames"

const styleSheet = theme => ({
  root: {
    position: "relative",
    background: theme.palette.background.paper,
  },

  pullNext: {
    marginBottom: theme.spacing(-14),

    "& > $content": {
      paddingBottom: theme.spacing(12),
      paddingTop: theme.spacing(4),
    },
  },

  content: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
    padding: [theme.spacing(4), 0],
    position: "relative",
    minHeight: theme.spacing(6) * 3,

    [theme.breakpoints.up("sm")]: {
      minHeight: theme.spacing(6) * 7,
    },
  },

  background: {
    background: ["50%", "0"],
    backgroundSize: "cover",
    height: "100%",
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },

  backgroundMobile: {
    composes: "$background",

    [theme.breakpoints.up("sm")]: {
      display: "none",
    },

    "& + $background": {
      [theme.breakpoints.down("xs")]: {
        display: "none",
      },
    },
  },
})

function Hero({ classes, className: classNameProp, children, image, mobileImage, pullNext, picture }) {
  const className = classnames(
    classes.root,
    {
      [classes.pullNext]: pullNext,
    },
    classNameProp
  )

  return (
    <div className={className}>
      {mobileImage && (
        <div
          className={classes.backgroundMobile}
          style={{ backgroundImage: `url(${mobileImage})` }}
        />
      )}
      {image && <div className={classes.background} style={{ backgroundImage: `url(${image})` }} />}
      <div className={classes.content}>
        {children}
      </div>
    </div>
  )
}

Hero.propTypes = {
  image: PropTypes.string,
  mobileImage: PropTypes.string,
  pullNext: PropTypes.bool,
}

Hero.defaultProps = {
  pullNext: false,
}

export default withStyles(styleSheet)(Hero)
