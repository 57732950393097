import React from "react"
import withStyles from "@material-ui/core/styles/withStyles"
import Helmet from "react-helmet"

import { Link } from "react-router-dom"

import MainAppChrome from "../../chrome/MainAppChrome"
import Hero from "../../../components/ui-Hero"
import HeroTitle from "../../../components/ui-HeroTitle"
import HaloPicture from "../../../components/HaloPicture"
import PageSection from "../../../components/ui-PageSection"
import { Container, Row, Col } from "Grid"
import { Text, TextList, Title, Header, Headline } from "Text"

import Button from "@material-ui/core/Button"

import BioSection from "../../BioSection"
import SupportGroupBanner from "../../SupportGroupBanner"
import ServicePaymentOptions from "../../ServicePaymentOptions"
import ServiceCallInstructions from "../../ServiceCallInstructions"
import StructuredData from "../../../components/StructuredData"

import { SCHEDULE_URL, ASK_URL } from "../../../lib/urls"

import breastfeedingHaloImage from "../../../../static/images/jump-start-halo.jpg"

const styleSheet = theme => {
  return {
    heroPicture: {
      margin: [0, theme.spacing(5)],

      [theme.breakpoints.down("xs")]: {
        margin: theme.spacing(3),
      },

      [theme.breakpoints.down("sm")]: {
        maxWidth: theme.spacing(44),
      },
    },

    scheduleButton: {
      margin: theme.spacing(),

      "&:first-child": {
        marginLeft: 0,
      },

      [theme.breakpoints.down("xs")]: {
        display: "block",
        flex: [1, 0, "100%"],
      },
    },

    scheduleSection: {
      marginTop: theme.spacing(3),
      flexDirection: "row",
      flexWrap: "wrap",
      alignItems: "center",
    },

    pricingWell: {
      marginTop: theme.spacing(5),
    },
  }
}

const structuredData = {
  "@context": "http://schema.org/",
  "@type": "Service",
  name: "Loss Consultation",
  description: "Continuing to produce milk can be a difficult reminder following a loss. If you need help stopping milk production we’re here to help.",
  offers: {
    "@type": "Offer",
    priceCurrency: "USD",
    price: "0.00",
    url: "https://jalactation.com/pumping-support/after-loss",
  },
}

function LossConsultation({ classes }) {
  return (
    <MainAppChrome>
      <Helmet>
        <title>Loss Consultation</title>
        <meta
          name="description"
          content="Continuing to produce milk can be a difficult reminder following a loss. If you need help stopping milk production we’re here to help."
        />
      </Helmet>
      <Hero>
        <Container wide>
          <Row alignItems="center">
            <Col sm={12} md={5} lg={4}>
              <HaloPicture image={breastfeedingHaloImage} className={classes.heroPicture} />
            </Col>
            <Col xs={12} md={7} lg={8}>
              <HeroTitle
                title="Loss Consultation"
                subTitle="Continuing to produce milk can be a difficult reminder following a loss. If you need help stopping milk production we’re here to help."
              />

              <Container wide flex className={classes.scheduleSection}>
                <Button
                  color="primary"
                  variant="contained"
                  href={SCHEDULE_URL}
                  target="_blank"
                  className={classes.scheduleButton}
                >
                  Schedule a consultation
                </Button>
                <Button color="primary" href={ASK_URL} className={classes.scheduleButton}>
                  Ask a question
                </Button>
                <Button color="primary" href="#pricing" className={classes.scheduleButton}>
                  No cost
                </Button>
                <StructuredData data={structuredData} />
              </Container>
            </Col>
          </Row>
        </Container>
      </Hero>

      <PageSection gutterBottom>
        <Container wide>
          <Row>
            <Col md={7} xs={12}>

              <div className={classes.pricingWell} id="pricing">
                <Title paragraph>Pricing</Title>
                <Row>
                  <Col>
                    <Header paragraph>
                      Proud to offer at no cost at <a href="/contact">My Office</a>
                    </Header>
                  </Col>
                </Row>

              </div>
            </Col>
            <Col md={1} xs={12} />
            <Col lg={3} md={4} xs={12}>
              <ServiceCallInstructions />
            </Col>
          </Row>
        </Container>
      </PageSection>
      <BioSection />
      <SupportGroupBanner />
    </MainAppChrome>
  )
}

export default withStyles(styleSheet)(LossConsultation)
