import React from "react"
import Helmet from "react-helmet"

import MainAppChrome from "../../chrome/MainAppChrome"
import Hero from "../../../components/ui-Hero"
import HeroTitle from "../../../components/ui-HeroTitle"
import PageSection from "../../../components/ui-PageSection"
import { Container } from "Grid"
import { Subheading, Title, Text, TextList } from "Text"

export default function Terms() {
  return (
    <MainAppChrome>
      <Helmet>
        <title>Privacy Policy</title>
      </Helmet>
      <Hero>
        <HeroTitle wide={false} title="Privacy Policy " subTitle="Effective June 6, 2018" />
      </Hero>
      <PageSection>
        <Container>
          <Text>
            Thank you for choosing to be part of our community at Julie Alexander Lactation Support,
            LLC (“company”, “we”, “us”, or “our”). We are committed to protecting your personal
            information and your right to privacy. If you have any questions or concerns about our
            policy, or our practices with regards to your personal information, please contact us at{" "}
            <a href="mailto:customer-service@jalactation.com">customer-service@jalactation.com</a>.
          </Text>
          <Text>
            When you visit our website jalactation.com, and use our services, you trust us with your
            personal information. We take your privacy very seriously. In this privacy notice, we
            describe our privacy policy. We seek to explain to you in the clearest way possible what
            information we collect, how we use it and what rights you have in relation to it. We
            hope you take some time to read through it carefully, as it is important. If there are
            any terms in this privacy policy that you do not agree with, please discontinue use of
            our Sites and our services.
          </Text>
          <Text>
            This privacy policy applies to all information collected through our website (such as
            jalactation.com), and/or any related services, sales, marketing or events (we refer to
            them collectively in this privacy policy as the "<b>Sites</b>").
          </Text>
          <Text>
            <b>
              Please read this privacy policy carefully as it will help you make informed decisions
              about sharing your personal information with us.
            </b>
          </Text>

          <Title paragraph>What Information Do We Collect?</Title>

          <Subheading paragraph>Personal information you disclose to us</Subheading>

          <Text>
            We collect personal information that you voluntarily provide to us when expressing an
            interest in obtaining information about us or our products and services, when
            participating in activities on the Sites or otherwise contacting us.
          </Text>
          <Text>
            The personal information that we collect depends on the context of your interactions
            with us and the Sites, the choices you make and the products and features you use. The
            personal information we collect can include the following:
          </Text>
          <Text>
            Name and Contact Data. We collect your first and last name, email address, postal
            address, phone number, and other similar contact data.
          </Text>
          <Text>
            All personal information that you provide to us must be true, complete and accurate, and
            you must notify us of any changes to such personal information.
          </Text>

          <Subheading paragraph>Information automatically collected</Subheading>
          <Text>
            We automatically collect certain information when you visit, use or navigate the Sites.
            This information does not reveal your specific identity (like your name or contact
            information) but may include device and usage information, such as your IP address,
            browser and device characteristics, operating system, language preferences, referring
            URLs, device name, country, location, information about how and when you use our Sites
            and other technical information. This information is primarily needed to maintain the
            security and operation of our Sites, and for our internal analytics and reporting
            purposes.
          </Text>
          <Text>
            Like many businesses, we also collect information through cookies and similar
            technologies.
          </Text>

          <Subheading paragraph>Information collected from other sources</Subheading>
          <Text>
            We may obtain information about you from other sources, such as public databases, joint
            marketing partners, as well as from other third parties. Examples of the information we
            receive from other sources include: social media profile information; marketing leads
            and search results and links, including paid listings (such as sponsored links).
          </Text>

          <Title paragraph>How Do We Use Your Information?</Title>

          <Text>
            We use personal information collected via our Sites for a variety of business purposes
            described below. We process your personal information for these purposes in reliance on
            our legitimate business interests ("Business Purposes"), in order to enter into or
            perform a contract with you ("Contractual"), with your consent ("Consent"), and/or for
            compliance with our legal obligations ("Legal Reasons"). We indicate the specific
            processing grounds we rely on next to each purpose listed below.
          </Text>
          <Text>We use the information we collect or receive:</Text>
          <TextList>
            <li>
              Fulfill and manage your orders for Contractual reasons. We may use your information to
              fulfill and manage your orders, payments, returns, and exchanges made through the
              Sites.
            </li>
            <li>
              To protect our Sites for Business Purposes and/or for Legal Reasons. We may use your
              information as part of our efforts to keep our Sites safe and secure (for example, for
              fraud monitoring and prevention).
            </li>
            <li>
              To enforce our terms, conditions and policies for Business Purposes, Legal Reasons
              and/or possibly Contractual.
            </li>
            <li>
              To respond to legal requests and prevent harm for Legal Reasons. If we receive a
              subpoena or other legal request, we may need to inspect the data we hold to determine
              how to respond.
            </li>
          </TextList>

          <Title paragraph>Will Your Information Be Shared With Anyone?</Title>

          <Text>We only share and disclose your information in the following situations:</Text>

          <TextList>
            <li>
              Compliance with Laws. We may disclose your information where we are legally required
              to do so in order to comply with applicable law, governmental requests, a judicial
              proceeding, court order, or legal process, such as in response to a court order or a
              subpoena (including in response to public authorities to meet national security or law
              enforcement requirements).
            </li>
            <li>
              Vital Interests and Legal Rights. We may disclose your information where we believe it
              is necessary to investigate, prevent, or take action regarding potential violations of
              our policies, suspected fraud, situations involving potential threats to the safety of
              any person and illegal activities, or as evidence in litigation in which we are
              involved.
            </li>
            <li>
              Vendors, Consultants and Other Third-Party Service Providers. We may share your data
              with third party vendors, service providers, contractors or agents who perform
              services for us or on our behalf and require access to such information to do that
              work. Examples include: payment processing, data analysis, email delivery, hosting
              services, customer service and marketing efforts. We may allow selected third parties
              to use tracking technology on the Sites, which will enable them to collect data about
              how you interact with the Sites over time. This information may be used to, among
              other things, analyze and track data, determine the popularity of certain content and
              better understand online activity. Unless described in this Policy, we do not share,
              sell, rent or trade any of your information with third parties for their promotional
              purposes.
            </li>
            <li>
              Business Transfers. We may share or transfer your information in connection with, or
              during negotiations of, any merger, sale of company assets, financing, or acquisition
              of all or a portion of our business to another company.
            </li>
            <li>
              With your Consent. We may disclose your personal information for any other purpose
              with your consent.
            </li>
          </TextList>

          <Title paragraph>Do We Use Cookies And Other Tracking Technologies?</Title>

          <Text>
            We may use cookies and similar tracking technologies (like web beacons and pixels) to
            access or store information. Specific information about how we use such technologies and
            how you can refuse certain cookies is set out in our Cookie Policy.
          </Text>

          <Title paragraph>Do We Use Google Maps?</Title>
          <Text>
            This website, mobile application, or Facebook application uses Google Maps APIs. You may
            find the Google Maps APIs Terms of Service{" "}
            <a href="https://developers.google.com/maps/terms">here</a> . To better understand
            Google’s Privacy Policy, please refer to this{" "}
            <a href="https://policies.google.com/privacy">link</a> .
          </Text>
          <Text>
            By using our Maps API Implementation, you agree to be bound by Google’s Terms of
            Service.
          </Text>

          <Title paragraph>How Long Do We Keep Your Information?</Title>

          <Text>
            We will only keep your personal information for as long as it is necessary for the
            purposes set out in this privacy policy, unless a longer retention period is required or
            permitted by law (such as tax, accounting or other legal requirements).
          </Text>
          <Text>
            When we have no ongoing legitimate business need to process your personal information,
            we will either delete or anonymize it, or, if this is not possible (for example, because
            your personal information has been stored in backup archives), then we will securely
            store your personal information and isolate it from any further processing until
            deletion is possible.
          </Text>

          <Title paragraph>How Do We Keep Your Information Safe?</Title>

          <Text>
            We have implemented appropriate technical and organizational security measures designed
            to protect the security of any personal information we process. However, please also
            remember that we cannot guarantee that the internet itself is 100% secure. Although we
            will do our best to protect your personal information, transmission of personal
            information to and from our Sites is at your own risk. You should only access the
            services within a secure environment.
          </Text>

          <Title paragraph>Do We Collect Information From Minors?</Title>

          <Text>
            We do not knowingly solicit data from or market to children under 18 years of age. By
            using the Sites, you represent that you are at least 18 or that you are the parent or
            guardian of such a minor and consent to such minor dependent’s use of the Sites. If we
            learn that personal information from users less than 18 years of age has been collected,
            we will deactivate the account and take reasonable measures to promptly delete such data
            from our records. If you become aware of any data we have collected from children under
            age 18, please contact us at customer-service@jalactation.com.
          </Text>

          <Title paragraph>What Are Your Privacy Rights?</Title>

          <Text>
            If you are resident in the European Economic Area and you believe we are unlawfully
            processing your personal information, you also have the right to complain to your local
            data protection supervisory authority. You can find their contact details here:
            http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
          </Text>

          <Title paragraph>Do California Residents Have Specific Privacy Rights?</Title>

          <Text>
            California Civil Code Section 1798.83, also known as the “Shine The Light” law, permits
            our users who are California residents to request and obtain from us, once a year and
            free of charge, information about categories of personal information (if any) we
            disclosed to third parties for direct marketing purposes and the names and addresses of
            all third parties with which we shared personal information in the immediately preceding
            calendar year. If you are a California resident and would like to make such a request,
            please submit your request in writing to us using the contact information provided
            below.
          </Text>
          <Text>
            If you are under 18 years of age, reside in California, and have a registered account
            with the Sites, you have the right to request removal of unwanted data that you publicly
            post on the Sites. To request removal of such data, please contact us using the contact
            information provided below, and include the email address associated with your account
            and a statement that you reside in California. We will make sure the data is not
            publicly displayed on the Sites, but please be aware that the data may not be completely
            or comprehensively removed from our systems.
          </Text>

          <Title paragraph>Do We Make Updates To This Policy?</Title>

          <Text>
            We may update this privacy policy from time to time. The updated version will be
            indicated by an updated “Effective” date and the updated version will be effective as
            soon as it is accessible. If we make material changes to this privacy policy, we may
            notify you either by prominently posting a notice of such changes or by directly sending
            you a notification. We encourage you to review this privacy policy frequently to be
            informed of how we are protecting your information.
          </Text>
          <Title paragraph>How Can You Contact Us About This Policy?</Title>
          <Text>
            If you have questions or comments about this policy, you may email us at
            customer-service@jalactation.com or by post to:
          </Text>
          <Text>
            Julie Alexander Lactation Support, LLC <br />
            42274 Faber Ct <br />
            Temecula, CA 92592 <br />
            United States
          </Text>
          <Text>
            If you have any further questions or comments about us or our policies, email us at
            customer-service@jalactation.com or by post to:
          </Text>

          <Text>
            Julie Alexander Lactation Support, LLC <br />
            42274 Faber Ct <br />
            Temecula, CA 92592 <br />
            United States <br />
            Phone: (951) 526-6870
          </Text>
        </Container>
      </PageSection>
    </MainAppChrome>
  )
}
