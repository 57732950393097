import React from "react"
import PropTypes from "prop-types"

import withStyles from "@material-ui/core/styles/withStyles"
import classnames from "classnames"

import CardContent from "@material-ui/core/CardContent"

const styleSheet = theme => ({
  root: {
    padding: [0, theme.spacing(6)],

    "&:last-child": {
      paddingBottom: 0,
    },

    [theme.breakpoints.down("xs")]: {
      padding: [0, theme.spacing(3)],
    },
  },

  disableGutters: {
    paddingLeft: 0,
    paddingRight: 0,
  },

  gutterTop: {
    paddingTop: theme.spacing(3),
  },

  gutterBottom: {
    "&, &:last-child": {
      paddingBottom: theme.spacing(3),
    },
  },
})

function StaticDialogContent({
  children,
  classes,
  className: classNameProp,
  disableGutters,
  gutterTop,
  gutterBottom,

  ...otherProps
}) {
  const className = classnames(
    classes.root,
    {
      [classes.disableGutters]: disableGutters,
      [classes.gutterTop]: gutterTop,
      [classes.gutterBottom]: gutterBottom,
    },
    classNameProp
  )

  return (
    <CardContent className={className} {...otherProps}>
      {children}
    </CardContent>
  )
}

StaticDialogContent.propTypes = {
  disableGutters: PropTypes.bool,
  gutterTop: PropTypes.bool,
  gutterBottom: PropTypes.bool,
}

export default withStyles(styleSheet)(StaticDialogContent)
