import { createMuiTheme } from "@material-ui/core/styles"
import yellow from "@material-ui/core/colors/yellow"

// http://mcg.mbitson.com/#!?mcgpalette0=%23be1780
export const palette = {
  brand: {
    50: "#f7e3f0",
    100: "#ecb9d9",
    200: "#df8bc0",
    300: "#d25da6",
    400: "#c83a93",
    500: "#be1780",
    600: "#b81478",
    700: "#af116d",
    800: "#a70d63",
    900: "#990750",
    A100: "#be1780",
    A200: "#be1780",
    A400: "#be1780",
    A700: "#be1780",
    contrastDefaultColor: "light",
  },
  blue: {
    50: "#e9f1f4",
    100: "#c9dbe3",
    200: "#a5c4d1",
    300: "#81acbe",
    400: "#669ab0",
    500: "#4b88a2",
    600: "#44809a",
    700: "#3b7590",
    800: "#336b86",
    900: "#235875",
    A100: "#b6e4ff",
    A200: "#83d0ff",
    A400: "#50bdff",
    A700: "#36b4ff",
    contrastDefaultColor: "light",
  },
}

export default function createTheme(direction, { settings, override } = {}) {
  let defaultSettings = {
    direction,
    palette: {
      primary: {
        light: palette.blue[300],
        main: palette.blue[500],
        dark: palette.blue[700],
      },
      secondary: {
        light: palette.brand[300],
        main: palette.brand[500],
        dark: palette.brand[700],
      },
    },
    overrides: {
      MuiButton: {
        contained: {
          backgroundColor: "#fff",
        },
      },

      MuiTableRow: {
        hover: {
          "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.12)",
          },
        },
      },
    },
    typography: {
      fontFamily:
        'system-ui, -apple-system, BlinkMacSystemFont, "Helvetica Neue", Helvetica, Arial, sans-serif',
    },
  }

  if (typeof settings === "function") {
    defaultSettings = settings(defaultSettings)
  }

  const theme = createMuiTheme(defaultSettings)

  theme.palette = {
    yellow,
    ...theme.palette,
    background: {
      ...theme.palette.background,
      workspace: "#f7f7f7",
    },
  }

  theme.typography.blockquote = {
    fontFamily: "inherit",
    overflow: "auto",
    color: theme.palette.text.secondary,
    margin: [theme.spacing(2), 0],
    padding: [0, theme.spacing(3)],
    borderLeft: [theme.spacing(), "solid", theme.palette.grey[200]],
    fontStyle: "italic",
  }

  theme.typography.monospace = {
    fontFamily: '"Roboto Mono", monospace',
    fontSize: "1rem",
    lineHeight: theme.typography.body1.lineHeight,
    fontWeight: theme.typography.fontWeightRegular,
  }

  theme.zIndex.sticky = theme.zIndex.appBar - 50

  delete theme.typography.button.textTransform

  if (typeof override === "function") return override(theme)

  return theme
}
