import React from "react"
import Helmet from "react-helmet"

import Divider from "Divider"

import MainAppChrome from "../../chrome/MainAppChrome"
import Bio from "../../BioSection"
import Testimonials from "../../Testimonials"

import Hero from "./Hero"
import Forms from "./Forms"
import Services from "./Services"
import ScheduleInline from "./ScheduleInline"
import SupportGroupBanner from "../../SupportGroupBanner"

export default function Landing() {
  return (
    <MainAppChrome>
      <Helmet>
        <title>Julie Alexander Lactation Support</title>
        <meta
          name="description"
          content="Non-judgmental support from an IBCLC certified lactation consultant to reach your lactation and breastfeeding goals. Be confident in feeding your infant helping them to grow up happy and healthy."
        />
        <meta name="keywords" content="lactation consultant, breastfeeding, lactation" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org",
            "@type": "MedicalBusiness",
            url: "https://jalactation.com",
            name: "JA Lactation Support",
            logo: "https://jalactation.com/images/logo-high-res.png",
            image: "https://jalactation.com/images/story-background.jpg",
            telephone: "+1-951-526-6870",
            email: "julie@jalactation.com",
            currenciesAccepted: "USD",
            paymentAccepted: "Cash, check, credit card",
            priceRange: "$$",
            openingHours: "Mo-Su",
            contactPoint: [
              {
                "@type": "ContactPoint",
                telephone: "+1-951-526-6870",
                email: "julie@jalactation.com",
                contactType: "customer service",
              },
            ],
            address: {
              "@type": "PostalAddress",
              addressLocality: "Temecula",
              addressRegion: "CA",
              postalCode: "92592",
              streetAddress: "42274 Faber Ct",
            },
            founder: {
              "@type": "Person",
              name: "Julie Alexander",
            },
          })}
        </script>
      </Helmet>
      <Hero />

      <Services />
      <ScheduleInline />
      <Divider light />
      <Bio />
      <SupportGroupBanner />
      <Testimonials />
      <Forms />
    </MainAppChrome>
  )
}
