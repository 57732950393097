// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../node_modules/css-loader/dist/cjs.js??ref--5-1!normalize.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
exports.push([module.id, "html {\n  min-height: 100%;\n  height: 100%;\n  position: relative;\n}\n\nbody  {\n  background: #fff;\n  overflow: hidden;\n  min-height: 100%;\n  height: 100%;\n  position: relative;\n}\n\n#root {\n  background: #f7f7f7;\n  display: flex;\n  height: 100%;\n  overflow: auto;\n}\n\n._2oWI3UFj5srrkYku-y02nt {\n  width: 100%;\n  flex: 1;\n  display: flex;\n}\n\n* {\n  box-sizing: border-box;\n}\n\na {\n  text-decoration-skip: ink;\n  text-decoration-skip-ink: auto;\n  color: #be1780;\n}\n\nol, ul {\n  padding: 0 0 0 2em;\n  margin: 0;\n}\n\ninput::-webkit-outer-spin-button,\ninput::-webkit-inner-spin-button {\n  display: none;\n  -webkit-appearance: none;\n}\n\ninput[type='number'] {\n  -moz-appearance:textfield;\n  -webkit-appearance:textfield;\n}\n\ncode, pre {\n  font-family: \"Roboto Mono\", monospace;\n}\n\n@media print {\n  html, body {\n    width: 8.5in;\n  }\n\n  #root {\n    color: red;\n    background: #fff;\n  }\n\n  .hidden-print {\n    display: none;\n  }\n}\n\n@page {\n  size: letter;\n}\n", ""]);
// Exports
exports.locals = {
	"root": "_2oWI3UFj5srrkYku-y02nt"
};
module.exports = exports;
