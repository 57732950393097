// List of languages from
// https://github.com/shadiabuhilal/rtl-detect/blob/e9e40b8aedc3ba17b1114b057b41b11af491ce08/lib/rtl-detect.js#L136

export default function isRtlClient() {
  if (DEV && /rtl=true/.test(location.search)) {
    return true
  }

  const language = navigator.languages[0] || ""

  return rtlLangs.find(
    l => language === l || (language.indexOf(l) === 0 && language.charAt(l.length) === "-")
  )
}

const rtlLangs = [
  "ar" /* 'العربية', Arabic */,
  "arc" /* Aramaic */,
  "bcc" /* 'بلوچی مکرانی', Southern Balochi */,
  "bqi" /* 'بختياري', Bakthiari */,
  "ckb" /* 'Soranî / کوردی', Sorani */,
  "dv" /* Dhivehi */,
  "fa" /* 'فارسی', Persian */,
  "glk" /* 'گیلکی', Gilaki */,
  "he" /* 'עברית', Hebrew */,
  "ku" /* 'Kurdî / كوردی', Kurdish */,
  "mzn" /* 'مازِرونی', Mazanderani */,
  "pnb" /* 'پنجابی', Western Punjabi */,
  "ps" /* 'پښتو', Pashto, */,
  "sd" /* 'سنڌي', Sindhi */,
  "ug" /* 'Uyghurche / ئۇيغۇرچە', Uyghur */,
  "ur" /* 'اردو', Urdu */,
  "yi" /* 'ייִדיש', Yiddish */,
]
