import React from "react"
import PropTypes from "prop-types"

import classnames from "classnames"
import withStyles from "@material-ui/core/styles/withStyles"

export const styleSheet = theme => ({
  root: theme.mixins.gutters({
    maxWidth: "100vw",
    margin: [0, "auto"],
    width: "100%",

    [theme.breakpoints.up("md")]: {
      maxWidth: theme.breakpoints.width("md"),
    },
  }),

  colorDefault: {
    backgroundColor: theme.palette.background.default,
  },

  colorPaper: {
    backgroundColor: theme.palette.background.paper,
  },

  colorNone: {
    backgroundColor: "initial",
  },

  colorInherit: {
    backgroundColor: "inherit",
  },

  fullWidth: { maxWidth: "100vw" },

  flex: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },

  positionStatic: {
    position: "static",
  },

  positionRelative: {
    position: "relative",
  },

  narrow: {
    maxWidth: theme.spacing(8) * 12 + theme.spacing(6), // 12 blocks + 3 unit padding on each side
  },

  wide: { maxWidth: theme.breakpoints.width("lg") },

  disableGutters: {
    paddingLeft: 0,
    paddingRight: 0,
  },

  rootRoot: {
    [theme.breakpoints.down("xs")]: {
      "&&": {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
  },

  actions: {
    marginLeft: theme.spacing(-2),
    marginRight: theme.spacing(-2),
    paddingLeft: 0,
    paddingRight: 0,
  },

  actionsDense: {
    marginLeft: theme.spacing(-1),
    marginRight: theme.spacing(-1),
  },

  gutterTop: { marginTop: theme.spacing(2) },
  gutterBottom: { marginBottom: theme.spacing(2) },
})

function Container({
  actions,
  children,
  className: classNameProp,
  classes,
  color,
  disableGutters,
  flex,
  fullWidth,
  gutterBottom,
  gutterTop,
  narrow,
  position,
  root,
  wide,
  ...otherProps
}) {
  const className = classnames(
    classes.root,
    {
      [classes.actions]: actions,
      [classes.actionsDense]: actions === "dense",
      [classes.disableGutters]: disableGutters,
      [classes.fullWidth]: fullWidth,
      [classes.narrow]: narrow,
      [classes.wide]: wide,
      [classes.rootRoot]: root && !disableGutters,
      [classes.flex]: flex,
      [classes.gutterBottom]: gutterBottom,
      [classes.gutterTop]: gutterTop,
      [classes.colorInherit]: color === "inherit",
      [classes.colorNone]: color === "none",
      [classes.colorDefault]: color === "default",
      [classes.colorPaper]: color === "paper",
      [classes.positionRelative]: position === "relative",
      [classes.positionStatic]: position === "static",
    },
    classNameProp
  )

  return (
    <div {...otherProps} className={className}>
      {children}
    </div>
  )
}

Container.propTypes = {
  actions: PropTypes.oneOfType([PropTypes.bool, PropTypes.oneOf(["dense"])]),
  children: PropTypes.node,
  className: PropTypes.string,
  classes: PropTypes.object,
  color: PropTypes.oneOf(["inherit", "none", "default", "paper"]),
  disableGutters: PropTypes.bool,
  flex: PropTypes.bool,
  fullWidth: PropTypes.bool,
  gutterBottom: PropTypes.bool,
  gutterTop: PropTypes.bool,
  narrow: PropTypes.bool,
  position: PropTypes.oneOf(["static", "relative"]),
  root: PropTypes.bool,
  wide: PropTypes.bool,
}

Container.defaultProps = {
  position: "static",
  actions: false,
  color: "inherit",
}

export default withStyles(styleSheet)(Container)
