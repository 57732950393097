import React, { Component } from "react"
import PropTypes from "prop-types"
import compose from "recompose/compose"
import withStyles from "@material-ui/core/styles/withStyles"
import classnames from "classnames"
import withWidth, { isWidthDown } from "@material-ui/core/withWidth"

import Grid from "@material-ui/core/Grid"

export const styleSheet = _theme => ({
  root: {},
  symmetricGutters: {
    "&&": {
      marginTop: 0,
      marginBottom: 0,
    },
  },
})

export class Row extends Component {
  static propTypes = {
    classes: PropTypes.object,
    gutterBottom: PropTypes.bool,
    gutterTop: PropTypes.bool,
    spacing: PropTypes.oneOfType([PropTypes.number, PropTypes.arrayOf(PropTypes.number)]),
    symmetricGutters: PropTypes.bool,
    width: PropTypes.string,
  }

  render() {
    const {
      classes,
      className: classNameProp,
      children: childrenProp,
      gutterTop,
      gutterBottom,
      spacing: spacingProp,
      symmetricGutters,
      width,

      ...otherProps
    } = this.props

    const className = classnames(
      classes.root,
      {
        [classes.symmetricGutters]: symmetricGutters,
      },
      classNameProp
    )

    let spacing = spacingProp || [1, 2]
    if (Array.isArray(spacing)) {
      spacing = spacing[isWidthDown("xs", width) ? 0 : 1]
    }

    let children = childrenProp
    if (gutterTop || gutterBottom) {
      children = React.Children.map(childrenProp, c => {
        const newProps = {}

        if (!c.props.hasOwnProperty("gutterTop")) {
          newProps.gutterTop = gutterTop
        }

        if (!c.props.hasOwnProperty("gutterBottom")) {
          newProps.gutterBottom = gutterBottom
        }

        return React.cloneElement(c, newProps)
      })
    }

    return (
      <Grid container spacing={spacing} className={className} {...otherProps}>
        {children}
      </Grid>
    )
  }
}

export default compose(withWidth(), withStyles(styleSheet))(Row)
