import React from "react"
import withStyles from "@material-ui/core/styles/withStyles"

import { Text, Title, Headline } from "Text"
import Button from "@material-ui/core/Button"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"

const styleSheet = theme => ({
  root: {},

  pricing: {
    textAlign: "right",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.getContrastText(theme.palette.primary.main),
    display: "flex",
    justifyContent: "flex-end",
  },

  instructions: {
    textAlign: "center",
  },

  callButton: {
    fontSize: theme.typography.title.fontSize,
    lineHeight: theme.typography.title.lineHeight,
    margin: [theme.spacing(1), 0],
    display: "flex",
    padding: [theme.spacing(1.5), theme.spacing(3)],
  },
})

function ServiceCallInstructions({ classes }) {
  return (
    <Card className={classes.root}>
      <CardContent className={classes.instructions}>
        <Headline className={classes.cta}>Call to schedule</Headline>
        <Text paragraph>24 hours a day, 7 days a week</Text>
        <Title paragraph className={classes.cta}>
          <Button
            href="tel:19515266870"
            color="primary"
            className={classes.callButton}
            size="large"
          >
            (951) 526-6870
          </Button>
        </Title>
        <Text paragraph={false}>
          Serving Temecula, Murrieta, Menifee, Fallbrook, Pala and surrounding areas.
        </Text>
      </CardContent>
    </Card>
  )
}

export default withStyles(styleSheet)(ServiceCallInstructions)
