import React from "react"
import { MuiThemeProvider } from "@material-ui/core/styles"
import createTheme from "theme"

import { create } from "jss"
// import rtl from "jss-rtl"
import expand from "jss-plugin-expand"
import functions from "jss-plugin-rule-value-function"
import global from "jss-plugin-global"
import nested from "jss-plugin-nested"
import camelCase from "jss-plugin-camel-case"
import compose from "jss-plugin-compose"
import defaultUnit from "jss-plugin-default-unit"
import vendorPrefixer from "jss-plugin-vendor-prefixer"
import propsSort from "jss-plugin-props-sort" // Subset of jss-preset-default with only the plugins the Material-UI components are using.
import { StylesProvider } from "@material-ui/core/styles"

import isRtlClient from "Rtl/isRtlClient"

// Configure JSS
const jss = create({
  plugins: [
    functions(),
    global(),
    nested(),
    camelCase(),
    defaultUnit(),
    expand(),
    vendorPrefixer(),
    compose(),
    propsSort(),
  ],
})

if (isRtlClient()) {
  document.dir = "rtl"
}
const defaultTheme = createTheme(document.dir)

export default function Material({ children, theme }) {
  return (
    <StylesProvider jss={jss}>
      <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
    </StylesProvider>
  )
}

Material.defaultProps = {
  theme: defaultTheme,
}
