import React from "react"
import PropTypes from "prop-types"
import withWidth, { isWidthDown } from "@material-ui/core/withWidth"

// iOS plays games with vh units and scrolling behavior. So capture the
// actual available space and force the component to that height so that
// it won't automatically grow when the user scrolls.
export default function withMobileMaxHeight() {
  return TargetComponent => {
    class MaxHeightComponent extends React.Component {
      static displayName = `${TargetComponent && TargetComponent.displayName}WithMaxHeight`
      static propTypes = {
        width: PropTypes.string,
        element: PropTypes.object,
      }

      componentDidMount() {
        window.addEventListener("resize", this._setMaxWindowHeight)
        this._setMaxWindowHeight()

        if (module.hot) {
          module.hot.accept(this._setMaxWindowHeight)
        }
      }

      componentWilUnmount() {
        window.removeEventListener("resize", this._setMaxWindowHeight)
      }

      _captureElement = c => {
        this._element = c
        this._setMaxWindowHeight()
      }

      _setMaxWindowHeight = () => {
        const { width, element: elementProp } = this.props
        const element = elementProp || this._element

        if (!element) {
          return
        }

        if (isWidthDown("xs", width)) {
          element.style.height = `${document.documentElement.clientHeight}px`
          element.style.maxHeight = `${document.documentElement.clientHeight}px`
          element.style.minHeight = "0"
        } else {
          element.style.height = undefined
          element.style.maxHeight = undefined
          element.style.minHeight = undefined
        }
      }

      render() {
        const {
          width,
          element,

          ...otherProps
        } = this.props

        if (TargetComponent) {
          return <TargetComponent elementRef={this._captureElement} {...otherProps} />
        } else {
          return this.props.children
        }
      }
    }

    return withWidth()(MaxHeightComponent)
  }
}
