import React, { PureComponent, Component } from "react"
import Typography from "@material-ui/core/Typography"
import PropTypes from "prop-types"

import classnames from "classnames"
import styleSheet from "./styles"
import withStyles from "@material-ui/core/styles/withStyles"

class UnstyledText extends PureComponent {
  static propTypes = {
    /**
     * Make the text more subtle.
     */
    quiet: PropTypes.bool,

    /**
     * The typography variant.
     */
    variant: PropTypes.string,

    /**
     * The JSS compiled classes used to apply styling.
     */
    classes: PropTypes.object,

    /**
     * The named color class to use.
     */
    color: PropTypes.string,

    /**
     * Additional `classes` to include on the composed typography component.
     */
    additionalClasses: PropTypes.arrayOf(PropTypes.string),
  }

  render() {
    const {
      quiet,
      classes,
      className: classNameProp,
      color,
      additionalClasses,
      ...otherProps
    } = this.props

    const className = classnames(
      (additionalClasses || []).map(a => classes[a]),
      {
        [classes.quiet]: quiet,
        [classes.primaryColor]: color === "primary",
      },
      classNameProp
    )

    return (
      <Typography
        {...otherProps}
        color={color === "primary" ? undefined : color}
        className={className}
      />
    )
  }
}

export const BaseText = withStyles(styleSheet)(UnstyledText)

export class Text extends PureComponent {
  render() {
    return (
      <BaseText
        paragraph
        component="div"
        variant="body2"
        additionalClasses={["text"]}
        {...this.props}
      />
    )
  }
}

/* eslint-disable react/no-multi-comp */

export class Headline extends PureComponent {
  render() {
    return <BaseText variant="h5" {...this.props} />
  }
}

export class Title extends PureComponent {
  render() {
    return <BaseText variant="h6" {...this.props} />
  }
}

export class Subheading extends PureComponent {
  render() {
    return <BaseText variant="h6" {...this.props} />
  }
}

export class Header extends PureComponent {
  render() {
    return <BaseText variant="body1" {...this.props} />
  }
}

export class Caption extends PureComponent {
  render() {
    return <BaseText variant="caption" {...this.props} />
  }
}

export class Display extends PureComponent {
  static propTypes = { level: PropTypes.oneOf([1, 2, 3, 4]) }

  static defaultProps = { level: 1 }

  render() {
    const { level, ...otherProps } = this.props

    return <BaseText variant={`h${level}`} {...otherProps} />
  }
}

class UnstyledBase extends Component {
  static propTypes = {
    classes: PropTypes.object,

    component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  }

  static defaultProps = {
    component: "span",
  }

  render() {
    const { classes, children, component: Component, ...otherProps } = this.props
    const className = classnames(classes.base, this.props.className)

    return (
      <Component {...otherProps} className={className}>
        {children}
      </Component>
    )
  }
}

export const Base = withStyles(styleSheet)(UnstyledBase)

class UnstyledCopy extends Component {
  static propTypes = { classes: PropTypes.object }

  render() {
    const { children, classes, ...otherProps } = this.props
    const className = classnames(classes["copy"], this.props.className)

    return (
      <div {...otherProps} className={className}>
        {children}
      </div>
    )
  }
}

export const Copy = withStyles(styleSheet)(UnstyledCopy)

function UnstyledTextList({
  className: classNameProp,
  classes,
  ordered,
  paragraph,

  ...otherProps
}) {
  const className = classnames(
    classes.textList,
    {
      [classes.textListParagraph]: paragraph,
    },

    classNameProp
  )

  const Component = ordered ? "ol" : "ul"

  return <Component className={className} {...otherProps} />
}

UnstyledTextList.propTypes = {
  ordered: PropTypes.bool,
  paragraph: PropTypes.bool,
}

UnstyledTextList.defaultProps = {
  paragraph: true,
}

export const TextList = withStyles(styleSheet)(UnstyledTextList)
