import React from "react";
import Helmet from "react-helmet";
import withStyles from "@material-ui/core/styles/withStyles";

import MainAppChrome from "../../chrome/MainAppChrome";
import Hero from "../../../components/ui-Hero";
import HeroTitle from "../../../components/ui-HeroTitle";
import PageSection from "../../../components/ui-PageSection";
import LinkCard from "../../../components/ui-LinkCard";

import { Container, Row, Col } from "Grid";
import { Headline, Text } from "Text";

import tileImage from "../../../../static/images/flower-tile.jpg";

const styleSheet = theme => ({
  notice: {
    background: theme.palette.secondary.main,
    color: theme.palette.getContrastText(theme.palette.secondary.main),
    padding: theme.spacing(1, 2),
    margin: theme.spacing(1, 0),
    borderRadius: theme.shape.borderRadius,

    "& a": {
      color: "inherit"
    }
  }
});

export function ClassesAndEvents({ classes }) {
  return (
    <MainAppChrome>
      <Helmet>
        <title>Classes and Events</title>
      </Helmet>
      <Hero>
        <HeroTitle title="Classes and Events" />
      </Hero>
      <PageSection>
        <Container wide>
          <Row spacing={0} justify="space-around">
            <Col xs={12} md={4}>
              <Headline id="breastfeeding-support-groups" gutterBottom>
                Breastfeeding Classes
              </Headline>
              <Text>
                Families can be surprised at how difficult breastfeeding can be.
                Classes are available to teach you how to avoid common
                breastfeeding challenges, how to know your baby is getting
                enough and so much more.
              </Text>
            </Col>
            <Col xs={12} md={6}>
              <LinkCard
                image={tileImage}
                title="Jump Start Class"
                href="/jump-start-breastfeeding-class"
                linkLabel="Learn More"
              >
                Best to be taken while you are pregnant, this class will give
                you a jumpstart on your breastfeeding journey!
              </LinkCard>
            </Col>
          </Row>
        </Container>
      </PageSection>

      <PageSection gutterBottom>
        <Container wide>
          <Row spacing={0} justify="space-around">
            <Col xs={12} md={4}>
              <Headline id="breastfeeding-support-groups" gutterBottom>
                Support Groups
              </Headline>
              <Text>
                The support group is a place for you to be able to hang out with
                other Mommas and build community, and get your breastfeeding
                questions answered!
              </Text>
              <Text>Pregnant Mommas are encouraged to come!</Text>
            </Col>
            <Col xs={12} md={6}>
              <LinkCard
                image={tileImage}
                title="Temecula Valley Infant Feeding Support Group"
                href="https://goo.gl/maps/hZg6FYBkWCKiDwKx6"
                linkLabel="Get Directions"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Text>
                  Join JA Lactaton Support every Tuesday from 9:00am to 10:00am at
                  Harveston Lake playground (off Balboa drive in Temecula).
                </Text>
                <Text>
                  Please bring a chair or blanket. I can't wait to see you
                  there!
                </Text>
              </LinkCard>
            </Col>
          </Row>
        </Container>
      </PageSection>
    </MainAppChrome>
  );
}

export default withStyles(styleSheet)(ClassesAndEvents);
