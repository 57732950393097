import React from "react"
import withStyles from "@material-ui/core/styles/withStyles"
import Helmet from "react-helmet"

import { Text, Headline } from "Text"

import StaticDialog from "StaticDialog"
import StaticDialogBody from "StaticDialogBody"
import StaticDialogContainer from "StaticDialogContainer"
import StaticDialogContent from "StaticDialogContent"
import StaticDialogFootnote from "StaticDialogFootnote"
import StaticDialogHeader from "StaticDialogHeader"

const styleSheet = theme => ({
  logo: {
    height: theme.spacing(10),
  },
})

function Missing({ classes }) {
  return (
    <StaticDialogContainer>
      <StaticDialog>
        <StaticDialogHeader>
          <a href="/">
            <img
              src={require("../../../../static/images/logo.svg")}
              alt="JA Lactation Support"
              className={classes.logo}
            />
          </a>
        </StaticDialogHeader>
        <StaticDialogBody>
          <StaticDialogContent>
            <Helmet>
              <title>Oops, can't find that page - 404 Not Found</title>
            </Helmet>
            <Headline paragraph>Oops, can't find that page</Headline>
            <Text>Sorry about that! Perhaps one of these links will help you get started?</Text>
            <Text component="div">
              <ul>
                <li>
                  <a href="/breastfeeding-support/in-home-consultation">
                    In Home Postpartum Consultation
                  </a>
                </li>
                <li>
                  <a href="/breastfeeding-support">Breastfeeding Support Services</a>
                </li>
                <li>
                  <a href="/">Home Page</a>
                </li>
              </ul>
            </Text>
            <Text />
          </StaticDialogContent>
        </StaticDialogBody>
        <StaticDialogFootnote>404 Not Found</StaticDialogFootnote>
      </StaticDialog>
    </StaticDialogContainer>
  )
}

export default withStyles(styleSheet)(Missing)
