export default theme => ({
  base: {
    ...theme.typography.body1,

    "& mark": { backgroundColor: theme.palette.yellow[300] },

    "& blockquote": {
      ...theme.typography.blockquote,

      "& p": { color: theme.typography.blockquote.color },
    },

    // [theme.breakpoints.down("xs")]: {
    //   // Force inputs to be at least 16px so iOS doesn't have to zoom in. This
    //   // will be overridden by explicit body1/2 text sizes for typography.
    //   fontSize: "1rem",
    // },
  },

  "@global a": { color: theme.palette.primary["A700"] },

  text: {
    "& ul, & ol": {
      padding: [0, 0, 0, "2em"],
    },

    "& dt": {
      marginTop: theme.spacing(),
      marginBottom: theme.spacing(),
    },

    "& dd": {
      color: theme.palette.text.secondary,
      marginLeft: theme.spacing(2),
    },
  },

  textList: {
    ...theme.typography.body1,
    padding: [0, 0, 0, "2em"],

    "& > li + li": {
      marginTop: "0.5rem",
    },
  },

  textListParagraph: {
    margin: [0, 0, "1em", 0],
  },

  copy: {
    maxWidth: "7in",
  },

  quiet: {
    color: theme.palette.text.hint,
    "& a": { color: "inherit" },
  },

  primaryColor: { color: theme.palette.primary[500] },
})
