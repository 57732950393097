import React from "react"
import PropTypes from "prop-types"

import { withStyles } from "@material-ui/core/styles"
import classnames from "classnames"

import ErrorBoundary from "ErrorBoundary"

export const styleSheet = _theme => ({
  root: {
    display: "flex",
    flex: 1,
    maxHeight: "100%",
    maxWidth: "100%",
    flexDirection: "column",
  },

  container: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    overflow: "hidden",
  },
})

function ScrollFrame({ classes, className: classNameProp, children, ...otherProps }) {
  const className = classnames(classes.root, classNameProp)

  return (
    <div {...otherProps} className={className} component="div">
      <ErrorBoundary>{children}</ErrorBoundary>
    </div>
  )
}

ScrollFrame.propTypes = {
  width: PropTypes.string,
}

export default withStyles(styleSheet)(ScrollFrame)
