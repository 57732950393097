import React from "react"
import PropTypes from "prop-types"

export default function StructuredData({ data }) {
  return <script type="application/ld+json">{JSON.stringify(data)}</script>
}

StructuredData.propTypes = {
  data: PropTypes.object,
}
