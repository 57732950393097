import React from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import classnames from "classnames"

import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import IconButton from "@material-ui/core/IconButton"
import Icon from "@material-ui/core/Icon"

import { fade } from "@material-ui/core/styles/colorManipulator"
import { Container } from "Grid"
import Collapse from "@material-ui/core/Collapse"

export const styleSheet = theme => ({
  root: {
    flexShrink: 0,
  },

  title: {
    ...theme.typography.subheading,

    color: "inherit",
    flex: 1,
  },

  container: { position: "relative" },

  toolbar: {
    [theme.breakpoints.down("xs")]: {
      minHeight: 48,
    },

    "& > div": {
      display: "flex",
      // overflow: "hidden",
    },
  },

  menuIcon: {
    marginLeft: theme.spacing(-2),
    marginRight: theme.spacing(2),

    [theme.breakpoints.down("sm")]: {
      marginRight: theme.spacing(),
    },
  },

  tools: {
    alignItems: "center",

    "& > div": { display: "flex" },
  },

  colorSecondary: {
    "& $toolbar": {
      color: fade(theme.palette.getContrastText(theme.palette.secondary.main), 0.56),
    },
  },

  colorInherit: {
    "& $toolbar": {
      color: theme.palette.secondary.main,
    },
  },
})

function TitleComponent({ title, titleIn }) {
  let el = title

  if (titleIn !== undefined) {
    el = <Collapse in={titleIn}>{el}</Collapse>
  }

  return el
}

function MainAppBar({
  children,
  classes,
  className: classNameProp,
  color,
  fullWidth,
  narrow,
  position,
  scrolled,
  theme,
  title,
  titleIn,
  tools,
  wide,

  onMenuClick,

  ...otherProps
}) {
  const className = classnames(
    classes.root,
    {
      [classes.colorSecondary]: color === "secondary",
      [classes.colorInherit]: color === "inherit",
    },
    classNameProp
  )

  return (
    <div {...otherProps} className={className}>
      <AppBar position={position} elevation={0} color={color}>
        <div className={classes.container}>
          <Container fullWidth={fullWidth} wide={wide} narrow={narrow}>
            <Toolbar disableGutters className={classes.toolbar}>
              {onMenuClick && (
                <div className={classes.menuIcon}>
                  <IconButton color="inherit" onClick={onMenuClick}>
                    <Icon>menu</Icon>
                  </IconButton>
                </div>
              )}
              <div className={classes.title}>
                <TitleComponent title={title} titleIn={titleIn} />
              </div>
              <div className={classes.tools}>{children}</div>
            </Toolbar>
          </Container>
        </div>
      </AppBar>
    </div>
  )
}

MainAppBar.propTypes = {
  color: PropTypes.oneOf(["inherit", "secondary"]),
  fullWidth: PropTypes.bool,
  narrow: PropTypes.bool,
  position: AppBar.Naked.propTypes.position,
  scrolled: PropTypes.bool,
  theme: PropTypes.object,
  title: PropTypes.node,
  titleIn: PropTypes.bool,
  tools: PropTypes.node,
  wide: PropTypes.bool,

  onMenuClick: PropTypes.func,
}

MainAppBar.defaultProps = {
  position: "static",
  color: "secondary",
}

export default withStyles(styleSheet)(MainAppBar)
