import React from "react"
import withStyles from "@material-ui/core/styles/withStyles"
import { fade } from "@material-ui/core/styles/colorManipulator"

import PageSection from "../../components/ui-PageSection"
import { Container, Row, Col } from "Grid"
import { Text, Display } from "Text"

import headshotImage from "../../../static/images/bio-pic.jpg"

const styleSheet = theme => ({
  pic: {
    borderRadius: "50%",
    margin: [theme.spacing(3), theme.spacing(6)],
    overflow: "hidden",
    position: "relative",

    [theme.breakpoints.up("sm")]: {
      margin: [0, theme.spacing(6), 0, 0],
    },

    "& img": {
      maxWidth: "100%",
      display: "block",
    },

    "&:after": {
      display: "block",
      content: "' '",
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      borderRadius: "50%",
      boxShadow: ["inset", 0, 0, 0, theme.spacing(2), fade(theme.palette.common.white, 0.5)],
    },
  },
})

function Bio({ classes }) {
  return (
    <PageSection color="paper" gutterTop gutterBottom>
      <Container narrow>
        <Row>
          <Col xs={12} sm={4}>
            <div className={classes.pic}>
              <img src={headshotImage} alt="Head shot of Julie Alexander" />
            </div>
          </Col>
          <Col xs={12} sm={8}>
            <Display paragraph level={4}>
              I'm Julie
            </Display>
            <Text>
              Breastfeeding four children was a real challenge for me. I struggled with latch, milk
              supply, and confidence. I needed help. I was fortunate to find a wonderful Lactation
              Consultant who helped me successfully feed my babies.
            </Text>

            <Text>
              I wanted to help moms facing similar challenges, so I took my BA in Biology from
              Westmont College and returned to school at UCSD to become an{" "}
              <a href="http://iblce.org" target="_blank" rel="noopener noreferrer">
                International Board Certified Lactation Consultant{" "}
              </a>{" "}
              (IBCLC).
            </Text>

            <Text tight="both">
              <a
                href="http://www.ilca.org/main/why-ibclc/ibclc"
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn more about IBCLCs
              </a>{" "}
              and how they help mothers around the world provide the best for their babies.
            </Text>
          </Col>
        </Row>
      </Container>
    </PageSection>
  )
}

export default withStyles(styleSheet)(Bio)
