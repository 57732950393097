import React from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router"

// Watch for navigation changes in a router and notify google analytics of the
// page navigation event.
class GoogleAnalyticsNavigation extends React.Component {
  componentDidMount() {
    const { history } = this.props

    this.unlisten = history.listen(location => {
      setTimeout(() => {
        if (typeof global.ga === "function") {
          global.ga("set", "page", location.pathname)
          global.ga("send", "pageview")
        }
      })
    })
  }

  componentWillUnmount() {
    if (this.unlisten) this.unlisten()
  }

  render() {
    const { children } = this.props

    return children || null
  }
}

GoogleAnalyticsNavigation.propTypes = {
  history: PropTypes.object,
}

export default withRouter(GoogleAnalyticsNavigation)
