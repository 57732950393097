import React from "react"

export default class ErrorBoundary extends React.Component {
  state = {}

  static getDerivedStateFromProps() {
    return { error: undefined, info: undefined }
  }

  componentDidCatch(error, info) {
    this.setState({ error, info })
  }

  render() {
    const { error } = this.state

    if (!error) {
      return this.props.children
    }

    // debugger // eslint-disable-line no-debugger

    return (
      <div
        style={{
          background: "rgba( 255, 0, 0, 0.15 )",
          flex: 1,
          font: "16px system-ui",
          padding: "16px",
        }}
      >
        Something went wrong. Please refresh and try again.
        {DEV && (
          <div>
            <p>{this.state.error.message || this.state.error.toString()}</p>
          </div>
        )}
      </div>
    )
  }
}
