export default {
  testimonials: [
    {
      rating: 5,
      name: "Lauren McCulloch",
      testimony:
        "Julie is caring and sensitive. She makes everyone around her feel comfortable and has both personal and professional experience.",
    },
    {
      rating: 5,
      name: "Stacy Galarz",
      testimony:
        "Julie is the best! I was unable to nurse my first born due to latch issues and poor help in the hospital and I was so desperate to nurse my last two babies. With the help of julie I've had so much success in doing so!! She was there with me in the first few days after they were born to get me started and guide me through proper technique and has since been available to me whenever I've had questions or needed any other help. She is knowledgeable, gentle, and so so helpful! I would recommend Julie to all first time mamas learning to nurse or anyone struggling to nurse.",
    },
    {
      rating: 5,
      name: "Vivian Welsh",
      testimony:
        "Julie is incredibly thorough. She is easy to talk to and very comforting. I would recommend her to my friends and family for sure.",
    },
    {
      rating: 5,
      name: "Brianna Alexander",
      testimony:
        "Julie is reassuring, knowledgeable and kind. A couple weeks after my daughter was born I panicked because I felt a lump under my arm pit. I was convinced I had cancer, but Julie patiently explained that milk ducts are in your arm pits and a lump could be the result of a clogged duct. She gave me a few things to do to reduce the lump and it went away in a few days. I was so thankful to have her as a resource.",
    },
    {
      rating: 5,
      name: "Michelle Jun",
      testimony:
        "Julie is so amazing! Due to some complications during delivery, my milk came in really late so we had to supplement and use SNS to feed my son. Julie helped me build my milk supply and wean off supplementation and SNS so that I could exclusively breastfeed.  She created 3-4 day feeding plans for us to follow and made sure my son was still gaining weight during the process. After 5 and a half weeks, I am exclusively breastfeeding (no more SNS!) and I couldn't have done it without Julie. She has been such a great support. She was always checking up on us, made herself available to answer any questions I had, researched things for me and whenever it got to be too much she helped me change our feeding plans and not give up. This has been such a journey and there were many times I wanted to give up. After going through all the complications I had to go through, I just didn't think it was in the cards for me to exclusively breastfeed my son but with Julie's support and a lot of determination we finally got there.  I am so grateful to have found Julie. She has been very encouraging, supportive and has been so helpful especially being a first time mom when everything is so overwhelming. Thank you so much Julie! Words cannot express my gratitude for all of your help and support.",
    },
  ],
}
