import React from "react"
import { Text, Caption, Header } from "Text"

export default function CancellationPolicy() {
  return (
    <>
      <Header paragraph>
         Cancellation policy
      </Header>

      <Text paragraph>
        We understand that life can get busy and unexpected circumstances arise. However, when you
        miss your appointment without notifying us at least 24 hours in advance, it affects our
        ability to assist other families in the community who may be on the waiting list, ensuring
        that every new baby receives the care they deserve. Without advanced notice you will be
        charged 50% of the appointment fee.
      </Text>
    </>
  )
}
