import React from "react"
import Helmet from "react-helmet"

import MainAppChrome from "../../chrome/MainAppChrome"
import Hero from "../../../components/ui-Hero"
import HeroTitle from "../../../components/ui-HeroTitle"
import PageSection from "../../../components/ui-PageSection"
import { Container } from "Grid"
import { Headline, Title, Text } from "Text"

export default function Terms() {
  return (
    <MainAppChrome>
      <Helmet>
        <title>Terms of Service</title>
      </Helmet>
      <Hero>
        <HeroTitle wide={false} title="Terms of Service " subTitle="Effective June 6, 2018" />
      </Hero>
      <PageSection>
        <Container>
          <div>
            <Headline paragraph>Agreement to Terms</Headline>
            <Text>
              These Terms of Use constitute a legally binding agreement made between you, whether
              personally or on behalf of an entity (“you”) and Julie Alexander Lactation Support,
              LLC (“we,” “us” or “our”), concerning your access to and use of the jalactation.com
              website as well as any other media form, media channel, mobile website or mobile
              application related, linked, or otherwise connected thereto (collectively, the
              “Site”). You agree that by accessing the Site, you have read, understood, and agreed
              to be bound by all of these Terms of Use. IF YOU DO NOT AGREE WITH ALL OF THESE TERMS
              OF USE, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE SITE AND YOU MUST DISCONTINUE
              USE IMMEDIATELY.
            </Text>

            <Text>
              Supplemental terms and conditions or documents that may be posted on the Site from
              time to time are hereby expressly incorporated herein by reference. We reserve the
              right, in our sole discretion, to make changes or modifications to these Terms of Use
              at any time and for any reason. We will alert you about any changes by updating the
              “Last updated” date of these Terms of Use, and you waive any right to receive specific
              notice of each such change. It is your responsibility to periodically review these
              Terms of Use to stay informed of updates. You will be subject to, and will be deemed
              to have been made aware of and to have accepted, the changes in any revised Terms of
              Use by your continued use of the Site after the date such revised Terms of Use are
              posted.
            </Text>

            <Text>
              The information provided on the Site is not intended for distribution to or use by any
              person or entity in any jurisdiction or country where such distribution or use would
              be contrary to law or regulation or which would subject us to any registration
              requirement within such jurisdiction or country. Accordingly, those persons who choose
              to access the Site from other locations do so on their own initiative and are solely
              responsible for compliance with local laws, if and to the extent local laws are
              applicable.
            </Text>
          </div>

          <div>
            <Headline paragraph>Intellectual Property Rights</Headline>

            <Text>
              Unless otherwise indicated, the Site is our proprietary property and all source code,
              databases, functionality, software, website designs, audio, video, text, photographs,
              and graphics on the Site (collectively, the “Content”) and the trademarks, service
              marks, and logos contained therein (the “Marks”) are owned or controlled by us or
              licensed to us, and are protected by copyright and trademark laws and various other
              intellectual property rights and unfair competition laws of the United States, foreign
              jurisdictions, and international conventions. The Content and the Marks are provided
              on the Site “AS IS” for your information and personal use only. Except as expressly
              provided in these Terms of Use, no part of the Site and no Content or Marks may be
              copied, reproduced, aggregated, republished, uploaded, posted, publicly displayed,
              encoded, translated, transmitted, distributed, sold, licensed, or otherwise exploited
              for any commercial purpose whatsoever, without our express prior written permission.
            </Text>

            <Text>
              Provided that you are eligible to use the Site, you are granted a limited license to
              access and use the Site and to download or print a copy of any portion of the Content
              to which you have properly gained access solely for your personal, non-commercial use.
              We reserve all rights not expressly granted to you in and to the Site, the Content and
              the Marks.
            </Text>
          </div>

          <div>
            <Headline paragraph>User Representations</Headline>

            <Text>
              By using the Site, you represent and warrant that: (1) you have the legal capacity and
              you agree to comply with these Terms of Use; (2) you are not a minor in the
              jurisdiction in which you reside; (3) you will not access the Site through automated
              or non-human means, whether through a bot, script, or otherwise; (4) you will not use
              the Site for any illegal or unauthorized purpose; and (5) your use of the Site will
              not violate any applicable law or regulation.
            </Text>

            <Text>
              If you provide any information that is untrue, inaccurate, not current, or incomplete,
              we have the right to suspend or terminate your account and refuse any and all current
              or future use of the Site (or any portion thereof).
            </Text>
          </div>

          <div>
            <Headline paragraph>Prohibited Activities</Headline>

            <Text>
              You may not access or use the Site for any purpose other than that for which we make
              the Site available. The Site may not be used in connection with any commercial
              endeavors except those that are specifically endorsed or approved by us.
            </Text>

            <Title paragraph>Submissions</Title>

            <Text>
              You acknowledge and agree that any questions, comments, suggestions, ideas, feedback,
              or other information regarding the Site ("Submissions") provided by you to us are
              non-confidential and shall become our sole property. We shall own exclusive rights,
              including all intellectual property rights, and shall be entitled to the unrestricted
              use and dissemination of these Submissions for any lawful purpose, commercial or
              otherwise, without acknowledgment or compensation to you. You hereby waive all moral
              rights to any such Submissions, and you hereby warrant that any such Submissions are
              original with you or that you have the right to submit such Submissions. You agree
              there shall be no recourse against us for any alleged or actual infringement or
              misappropriation of any proprietary right in your Submissions.
            </Text>

            <Title paragraph>Site Management</Title>

            <Text>
              We reserve the right, but not the obligation, to: (1) monitor the Site for violations
              of these Terms of Use; (2) take appropriate legal action against anyone who, in our
              sole discretion, violates the law or these Terms of Use, including without limitation,
              reporting such user to law enforcement authorities; (3) in our sole discretion and
              without limitation, refuse, restrict access to, limit the availability of, or disable
              (to the extent technologically feasible) any of your Contributions or any portion
              thereof; (4) in our sole discretion and without limitation, notice, or liability, to
              remove from the Site or otherwise disable all files and content that are excessive in
              size or are in any way burdensome to our systems; and (5) otherwise manage the Site in
              a manner designed to protect our rights and property and to facilitate the proper
              functioning of the Site.
            </Text>

            <Title paragraph>Term and Termination</Title>

            <Text>
              These Terms of Use shall remain in full force and effect while you use the Site.
              WITHOUT LIMITING ANY OTHER PROVISION OF THESE TERMS OF USE, WE RESERVE THE RIGHT TO,
              IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE
              SITE (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO
              REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY, OR
              COVENANT CONTAINED IN THESE TERMS OF USE OR OF ANY APPLICABLE LAW OR REGULATION. WE
              MAY TERMINATE YOUR USE OR PARTICIPATION IN THE SITE OR DELETE ANY CONTENT OR
              INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT WARNING, IN OUR SOLE DISCRETION.
            </Text>

            <Text>
              If we terminate or suspend your account for any reason, you are prohibited from
              registering and creating a new account under your name, a fake or borrowed name, or
              the name of any third party, even if you may be acting on behalf of the third party.
              In addition to terminating or suspending your account, we reserve the right to take
              appropriate legal action, including without limitation pursuing civil, criminal, and
              injunctive redress.
            </Text>

            <Title paragraph>Modifications and Interruptions</Title>

            <Text>
              We reserve the right to change, modify, or remove the contents of the Site at any time
              or for any reason at our sole discretion without notice. However, we have no
              obligation to update any information on our Site. We also reserve the right to modify
              or discontinue all or part of the Site without notice at any time. We will not be
              liable to you or any third party for any modification, price change, suspension, or
              discontinuance of the Site.
            </Text>

            <Text>
              We cannot guarantee the Site will be available at all times. We may experience
              hardware, software, or other problems or need to perform maintenance related to the
              Site, resulting in interruptions, delays, or errors. We reserve the right to change,
              revise, update, suspend, discontinue, or otherwise modify the Site at any time or for
              any reason without notice to you. You agree that we have no liability whatsoever for
              any loss, damage, or inconvenience caused by your inability to access or use the Site
              during any downtime or discontinuance of the Site. Nothing in these Terms of Use will
              be construed to obligate us to maintain and support the Site or to supply any
              corrections, updates, or releases in connection therewith.
            </Text>
          </div>

          <div>
            <Headline paragraph>Governing Law</Headline>

            <Text>
              These Terms of Use and your use of the Site are governed by and construed in
              accordance with the laws of the State of California applicable to agreements made and
              to be entirely performed within the State of California, without regard to its
              conflict of law principles.
            </Text>
          </div>

          <div>
            <Headline paragraph>Dispute Resolution</Headline>

            <Title paragraph>Binding Arbitration</Title>

            <Text>
              If the Parties are unable to resolve a Dispute through informal negotiations, the
              Dispute (except those Disputes expressly excluded below) will be finally and
              exclusively resolved by binding arbitration. YOU UNDERSTAND THAT WITHOUT THIS
              PROVISION, YOU WOULD HAVE THE RIGHT TO SUE IN COURT AND HAVE A JURY TRIAL. The
              arbitration shall be commenced and conducted under the Commercial Arbitration Rules of
              the American Arbitration Association ("AAA") and, where appropriate, the AAA’s
              Supplementary Procedures for Consumer Related Disputes ("AAA Consumer Rules"), both of
              which are available at the AAA website www.adr.org. Your arbitration fees and your
              share of arbitrator compensation shall be governed by the AAA Consumer Rules and,
              where appropriate, limited by the AAA Consumer Rules. The arbitration may be conducted
              in person, through the submission of documents, by phone, or online. The arbitrator
              will make a decision in writing, but need not provide a statement of reasons unless
              requested by either Party. The arbitrator must follow applicable law, and any award
              may be challenged if the arbitrator fails to do so. Except where otherwise required by
              the applicable AAA rules or applicable law, the arbitration will take place in
              Riverside County, California. Except as otherwise provided herein, the Parties may
              litigate in court to compel arbitration, stay proceedings pending arbitration, or to
              confirm, modify, vacate, or enter judgment on the award entered by the arbitrator.
            </Text>

            <Text>
              If for any reason, a Dispute proceeds in court rather than arbitration, the Dispute
              shall be commenced or prosecuted in the state and federal courts located in Riverside
              County, California, and the Parties hereby consent to, and waive all defenses of lack
              of personal jurisdiction, and forum non conveniens with respect to venue and
              jurisdiction in such state and federal courts. Application of the United Nations
              Convention on Contracts for the International Sale of Goods and the the Uniform
              Computer Information Transaction Act (UCITA) are excluded from these Terms of Use.
            </Text>

            <Text>
              If this provision is found to be illegal or unenforceable, then neither Party will
              elect to arbitrate any Dispute falling within that portion of this provision found to
              be illegal or unenforceable and such Dispute shall be decided by a court of competent
              jurisdiction within the courts listed for jurisdiction above, and the Parties agree to
              submit to the personal jurisdiction of that court.
            </Text>

            <Title paragraph>Restrictions</Title>

            <Text>
              The Parties agree that any arbitration shall be limited to the Dispute between the
              Parties individually. To the full extent permitted by law, (a) no arbitration shall be
              joined with any other proceeding; (b) there is no right or authority for any Dispute
              to be arbitrated on a class-action basis or to utilize class action procedures; and
              (c) there is no right or authority for any Dispute to be brought in a purported
              representative capacity on behalf of the general public or any other persons.
            </Text>

            <Title paragraph>Exceptions to Arbitration</Title>

            <Text>
              The Parties agree that the following Disputes are not subject to the above provisions
              concerning binding arbitration: (a) any Disputes seeking to enforce or protect, or
              concerning the validity of, any of the intellectual property rights of a Party; (b)
              any Dispute related to, or arising from, allegations of theft, piracy, invasion of
              privacy, or unauthorized use; and (c) any claim for injunctive relief. If this
              provision is found to be illegal or unenforceable, then neither Party will elect to
              arbitrate any Dispute falling within that portion of this provision found to be
              illegal or unenforceable and such Dispute shall be decided by a court of competent
              jurisdiction within the courts listed for jurisdiction above, and the Parties agree to
              submit to the personal jurisdiction of that court.
            </Text>
          </div>

          <div>
            <Headline paragraph>Corrections</Headline>

            <Text>
              There may be information on the Site that contains typographical errors, inaccuracies,
              or omissions, including descriptions, pricing, availability, and various other
              information. We reserve the right to correct any errors, inaccuracies, or omissions
              and to change or update the information on the Site at any time, without prior notice.
            </Text>
          </div>

          <div>
            <Headline paragraph>Disclaimer</Headline>

            <Text>
              THE SITE IS PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE THAT YOUR USE OF
              THE SITE AND OUR SERVICES WILL BE AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED
              BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE SITE
              AND YOUR USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF
              MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE MAKE NO
              WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE SITE’S CONTENT
              OR THE CONTENT OF ANY WEBSITES LINKED TO THE SITE AND WE WILL ASSUME NO LIABILITY OR
              RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS,
              (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR
              ACCESS TO AND USE OF THE SITE, (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE
              SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED
              THEREIN, (4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE SITE, (5)
              ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH
              THE SITE BY ANY THIRD PARTY, AND/OR (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND
              MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY
              CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SITE. WE DO NOT
              WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE
              ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE SITE, ANY HYPERLINKED WEBSITE, OR
              ANY WEBSITE OR MOBILE APPLICATION FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND WE
              WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION
              BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE
              PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD
              USE YOUR BEST JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE.
            </Text>
          </div>

          <div>
            <Headline paragraph>Limitations of Liability</Headline>

            <Text>
              IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR ANY
              THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL,
              OR PUNITIVE DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER
              DAMAGES ARISING FROM YOUR USE OF THE SITE, EVEN IF WE HAVE BEEN ADVISED OF THE
              POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED
              HEREIN, OUR LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF
              THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE LESSER OF THE AMOUNT PAID, IF ANY, BY
              YOU TO US OR FIVE HUNDRED DOLLARS USD ($500). CERTAIN STATE LAWS DO NOT ALLOW
              LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES.
              IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS OR LIMITATIONS MAY
              NOT APPLY TO YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS.
            </Text>
          </div>

          <div>
            <Headline paragraph>Indemnification</Headline>

            <Text>
              You agree to defend, indemnify, and hold us harmless, including our subsidiaries,
              affiliates, and all of our respective officers, agents, partners, and employees, from
              and against any loss, damage, liability, claim, or demand, including reasonable
              attorneys’ fees and expenses, made by any third party due to or arising out of: (1)
              use of the Site; (2) breach of these Terms of Use; (3) any breach of your
              representations and warranties set forth in these Terms of Use; (4) your violation of
              the rights of a third party, including but not limited to intellectual property
              rights; or (5) any overt harmful act toward any other user of the Site with whom you
              connected via the Site. Notwithstanding the foregoing, we reserve the right, at your
              expense, to assume the exclusive defense and control of any matter for which you are
              required to indemnify us, and you agree to cooperate, at your expense, with our
              defense of such claims. We will use reasonable efforts to notify you of any such
              claim, action, or proceeding which is subject to this indemnification upon becoming
              aware of it.
            </Text>
          </div>

          <div>
            <Headline paragraph>User Data</Headline>

            <Text>
              We will maintain certain data that you transmit to the Site for the purpose of
              managing the performance of the Site, as well as data relating to your use of the
              Site. Although we perform regular routine backups of data, you are solely responsible
              for all data that you transmit or that relates to any activity you have undertaken
              using the Site. You agree that we shall have no liability to you for any loss or
              corruption of any such data, and you hereby waive any right of action against us
              arising from any such loss or corruption of such data.
            </Text>
          </div>

          <div>
            <Headline paragraph>Electronic Communications, Transactions, and Signatures</Headline>

            <Text>
              Visiting the Site, sending us emails, and completing online forms constitute
              electronic communications. You consent to receive electronic communications, and you
              agree that all agreements, notices, disclosures, and other communications we provide
              to you electronically, via email and on the Site, satisfy any legal requirement that
              such communication be in writing. YOU HEREBY AGREE TO THE USE OF ELECTRONIC
              SIGNATURES, CONTRACTS, ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF
              NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE
              SITE. You hereby waive any rights or requirements under any statutes, regulations,
              rules, ordinances, or other laws in any jurisdiction which require an original
              signature or delivery or retention of non-electronic records, or to payments or the
              granting of credits by any means other than electronic means.
            </Text>
          </div>

          <div>
            <Headline paragraph>California Users and Residents</Headline>

            <Text>
              If any complaint with us is not satisfactorily resolved, you can contact the Complaint
              Assistance Unit of the Division of Consumer Services of the California Department of
              Consumer Affairs in writing at 1625 North Market Blvd., Suite N 112, Sacramento,
              California 95834 or by telephone at (800) 952-5210 or (916) 445-1254.
            </Text>
          </div>

          <div>
            <Headline paragraph>Miscellaneous</Headline>

            <Text>
              These Terms of Use and any policies or operating rules posted by us on the Site or in
              respect to the Site constitute the entire agreement and understanding between you and
              us. Our failure to exercise or enforce any right or provision of these Terms of Use
              shall not operate as a waiver of such right or provision. These Terms of Use operate
              to the fullest extent permissible by law. We may assign any or all of our rights and
              obligations to others at any time. We shall not be responsible or liable for any loss,
              damage, delay, or failure to act caused by any cause beyond our reasonable control. If
              any provision or part of a provision of these Terms of Use is determined to be
              unlawful, void, or unenforceable, that provision or part of the provision is deemed
              severable from these Terms of Use and does not affect the validity and enforceability
              of any remaining provisions. There is no joint venture, partnership, employment or
              agency relationship created between you and us as a result of these Terms of Use or
              use of the Site. You agree that these Terms of Use will not be construed against us by
              virtue of having drafted them. You hereby waive any and all defenses you may have
              based on the electronic form of these Terms of Use and the lack of signing by the
              parties hereto to execute these Terms of Use.
            </Text>
          </div>

          <div>
            <Headline paragraph>Contact Us</Headline>

            <Text>
              In order to resolve a complaint regarding the Site or to receive further information
              regarding use of the Site, please contact us at:
            </Text>

            <Text>
              42274 Faber Ct
              <br />
              Temecula CA, 92592
            </Text>

            <Text>951-526-6870</Text>
          </div>
        </Container>
      </PageSection>
    </MainAppChrome>
  )
}
