import React from "react"
import withStyles from "@material-ui/core/styles/withStyles"
import Helmet from "react-helmet"

import MainAppChrome from "../../chrome/MainAppChrome"
import Hero from "../../../components/ui-Hero"
import HeroTitle from "../../../components/ui-HeroTitle"
import PageSection from "../../../components/ui-PageSection"
import { Container, Row, Col } from "Grid"
import { Text, TextList, Title, Header } from "Text"

import Button from "@material-ui/core/Button"

import BioSection from "../../BioSection"
import SupportGroupBanner from "../../SupportGroupBanner"
import ServicePaymentOptions from "../../ServicePaymentOptions"
import ServiceCallInstructions from "../../ServiceCallInstructions"
import CancellationPolicy from "../../CancellationPolicy"
import StructuredData from "../../../components/StructuredData"

import { SCHEDULE_URL, ASK_URL } from "../../../lib/urls"

const styleSheet = theme => ({
  pricing: {
    margin: [theme.spacing(2), 0],

    [theme.breakpoints.up("md")]: {
      margin: [0, theme.spacing(2)],
    },
  },

  scheduleButton: {
    margin: theme.spacing(),

    "&:first-child": {
      marginLeft: 0,
    },

    [theme.breakpoints.down("xs")]: {
      display: "block",
      flex: [1, 0, "100%"],
    },
  },

  scheduleSection: {
    marginTop: theme.spacing(3),
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
  },

  pricingWell: {
    marginTop: theme.spacing(5),
  },
})

const structuredData = {
  "@context": "http://schema.org/",
  "@type": "Service",
  name: "In Pre-Baby Consultation",
  description:
    "This is a 60 minute in home consultation for families who may have questions about breastfeeding or have a history of complications when breastfeeding.",
  offers: {
    "@type": "Offer",
    priceCurrency: "USD",
    price: "165.00",
    url: "https://jalactation.com/breastfeeding-support/in-home-consultation",
  },
}

function PrenatalConsultation({ classes }) {
  return (
    <MainAppChrome>
      <Helmet>
        <title>Pre-Babby Consultation</title>
        <meta
          name="description"
          content="
              This is a 60 minute consultation for mothers (and
              their partners) who may have questions about breastfeeding or
              have a history of complications when breastfeeding.
              "
        />
      </Helmet>
      <Hero>
        <HeroTitle
          title="Pre-Baby Consultation"
          subTitle="
              This is a 60 minute consultation for mothers (and their partners) who may have
              questions about breastfeeding or have a history of complications when breastfeeding.
            "
        />

        <Container wide flex className={classes.scheduleSection}>
          <Button
            color="primary"
            variant="outlined"
            href={SCHEDULE_URL}
            target="_blank"
            className={classes.scheduleButton}
          >
            Schedule help
          </Button>
          <Button color="primary" href={ASK_URL} className={classes.scheduleButton}>
            Ask a question
          </Button>
          <Button color="primary" href="#pricing" className={classes.scheduleButton}>
            Pricing
          </Button>
          <StructuredData data={structuredData} />
        </Container>
      </Hero>

      <PageSection gutterTop gutterBottom>
        <Container wide>
          <Row>
            <Col md={7} xs={12}>
              <Title paragraph>What can you expect during your consultation?</Title>

              <TextList>
                <li>Review breastfeeding history</li>
                <li>Breast exam for mom</li>
                <li>Develop a personalized Breastfeeding Plan</li>
                <li>Mom will receive a follow up call two weeks before due date</li>
              </TextList>

              <Text paragraph>
                Following your visit I will send a report to your primary care provider(s)
                summarizing the consultation.
              </Text>

              <div className={classes.pricingWell} id="pricing">
                <Title paragraph>Pricing</Title>
                <Row>
                  <Col xs={12} sm={6}>
                    <Header paragraph>$165 In Your Home</Header>
                  </Col>
                  <Col>
                    <Header paragraph>
                      $125 At <a href="/contact">My Office</a>
                    </Header>
                  </Col>
                </Row>

                <ServicePaymentOptions />
                <CancellationPolicy />
              </div>
            </Col>
            <Col md={1} xs={12} />
            <Col lg={3} md={4} xs={12}>
              <ServiceCallInstructions />
            </Col>
          </Row>
        </Container>
      </PageSection>
      <BioSection />
      <SupportGroupBanner />
    </MainAppChrome>
  )
}

export default withStyles(styleSheet)(PrenatalConsultation)
