import React from "react"
import Helmet from "react-helmet"

import { Link } from "react-router-dom"
import Button from "@material-ui/core/Button"

import MainAppChrome from "../../chrome/MainAppChrome"
import Hero from "../../../components/ui-Hero"
import HeroTitle from "../../../components/ui-HeroTitle"
import PageSection from "../../../components/ui-PageSection"
import Divider from "Divider"
import { Container } from "Grid"
import { Headline, Text, Copy } from "Text"

export default function LactationServices() {
  return (
    <MainAppChrome>
      <Helmet>
        <title>Breastfeeding Support &amp; Lactation Services</title>
      </Helmet>
      <Hero>
        <HeroTitle
          title="Breastfeeding Support"
          subTitle="Reach your breastfeeding goals and help your infant grow up happy and healthy."
        />
      </Hero>
      <PageSection gutterBottom>
        <Container wide>
          <Copy>
            <Headline paragraph>In Home Consultation</Headline>
            <Text>
              This is a 60-90 minute in home consultation for mothers (and their partners) that need
              breastfeeding guidance. You may feel you lack milk, have pain when nursing, or face
              other challenges; I can help.
            </Text>
            <Button
              variant="contained"
              color="default"
              component={Link}
              to="/breastfeeding-support/in-home-consultation"
            >
              Consultation Details
            </Button>
          </Copy>
          <Divider gutterTop gutterBottom />
          <Copy>
            <Headline paragraph>Prenatal Consultation</Headline>
            <Text>
              This is a 30-60 minute in home consultation for mothers (and their partners) who may
              have questions about breastfeeding or have a history of complications when
              breastfeeding.
            </Text>
            <Button
              variant="contained"
              color="default"
              component={Link}
              to="/breastfeeding-support/prenatal-consultation"
            >
              Prenatal Details
            </Button>
          </Copy>
          <Divider gutterTop gutterBottom />
          <Copy>
            <Headline paragraph>Flange Fitting</Headline>
            <Text>
            </Text>
            <Button
              variant="contained"
              color="default"
              component={Link}
              to="/pumping-support/flange-fitting"
            >
              Fitting Details
            </Button>
          </Copy>
        </Container>
      </PageSection>
    </MainAppChrome>
  )
}
